import React, { useEffect, useState } from 'react';
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import axios from 'axios';

const AddSubcategory = () => {

    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);

    const [categories, setCategories] = useState([]);
    const [formData, setFormData] = useState({
        category_id: "",    
        name: "",
        image: "",
        description: ""
    });

    useEffect(() => {
        axios
            .get("https://api.vivekflowers.com/api/category")
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => console.error("Error fetching categories:", error));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, image: e.target.files[0] });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const postData = new FormData();
        postData.append('category_id', formData.category_id);
        postData.append('name', formData.name);
        postData.append('description', formData.description);
        postData.append('image', formData.image);

        axios
            .post("https://api.vivekflowers.com/api/subcategory", postData)
            .then((response) => {
                console.log("Subcategory added successfully:", response.data);
                toast.success("SubCategory added successfully!");


            })
            .catch((error) => console.error("Error adding subcategory:", error));
    };

    const handleCancel = () => {
        setFormData({
            category_id: "",
            name: "",
            image: "",
            description: ""
        });
    };

    return (
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper">
                <Header />
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-12 grid-margin stretch-card">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h4 className="card-title">Add Subcategory</h4>
                                                    <form className="forms-sample" onSubmit={handleSubmit}>
                                                        <div className="row">
                                                            {/* Category  */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="category_id">Category</label>
                                                                    <select
                                                                        className="form-select"
                                                                        name="category_id"
                                                                        value={formData.category_id}
                                                                        onChange={handleChange}
                                                                        required
                                                                    >
                                                                        <option value="" disabled>
                                                                            Select the Category
                                                                        </option>
                                                                        {categories.map((category) => (
                                                                            <option key={category.id} value={category.id}>
                                                                                {category.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            {/* Subcategory Name */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="name">Subcategory Name</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="name"
                                                                        value={formData.name}
                                                                        onChange={handleChange}
                                                                        placeholder="Enter Subcategory Name"
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* Description */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="description">Description</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="description"
                                                                        value={formData.description}
                                                                        onChange={handleChange}
                                                                        placeholder="Enter Description"
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* Image Upload */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="image">Upload Image</label>
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        onChange={handleFileChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* Buttons */}
                                                        <button type="submit" className="btn btn-primary btn-icon-text mr-2">
                                                            <i className="ti-file btn-icon-prepend"></i> Submit
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={handleCancel}
                                                            className="btn btn-primary btn-icon-text"
                                                            style={{ marginLeft: '10px' }}
                                                        >
                                                            <i className="ti-close btn-icon-prepend"></i> Cancel
                                                        </button>
                                                    </form>
                                                    {/* {successMessage && (
                                                        <div className="alert alert-success" role="alert">
                                                            {successMessage}
                                                        </div>
                                                    )} */}
                                                    <ToastContainer />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddSubcategory;
