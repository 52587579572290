import React, { useEffect, useState } from 'react';
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import axios from 'axios';
import { Pagination, Select, MenuItem, FormControl } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SubCategory = () => {

    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [subcategories, setSubCategories] = useState([]);
    const [search, setSearch] = useState('');
    const [rowsperpage, setRowsPerPage] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
   
    

    // console.log("subcategory",subcategories)

    // console.log("TotalPage:", totalPages)
    // console.log(currentPage);


    const handleEdit = (subcategories) => {
        navigate("/editsubcategory", { state: { subcategories } });
    };

    const fetchCategories = () => {
        axios
            .get('https://api.vivekflowers.com/api/category')
            .then(response => {
                setCategories(response.data);
                if (response.data.length > 0) {
                    setSelectedCategory(response.data[0].id);
                    fetchSubcategories(response.data[0].id);
                }
            })
            .catch(error => console.error('Error fetching categories:', error));
    };

    const handleCategoryChange = (e) => {
        const selectedCategoryId = e.target.value;
        setSelectedCategory(selectedCategoryId);
        setCurrentPage(1);
        fetchSubcategories(selectedCategoryId);
    };
    useEffect(() => {
        fetchCategories();
    }, []);
    console.log(categories);

    const fetchSubcategories = (categoryId) => {
        const params = {
            page: currentPage,
            per_page: rowsperpage,
            search,
            category_id: categoryId,
        };
        axios
            .get(`https://api.vivekflowers.com/api/subcategory-page/${categoryId}`, { params })
            .then(response => {
                const { subcategory = [], total_pages } = response.data;
                console.log("Responsing Total pages:", response.total_pages);
                setSubCategories(subcategory);
                
                setTotalPages(total_pages);
            })
            .catch(error => console.error('Error fetching products:', error));
    };


    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1);
    };

    const handleDelete = async (id) => {
        

        try {
            const response = await axios.put(`https://api.vivekflowers.com/api/subcategory/remove/${id}`,{
                remove:1,
            });
            if (response.status === 200) {
                alert("Subcategory deleted successfully!");
                setSubCategories(subcategories.filter((item) => item.id !== id)); 
            }
        } catch (error) {
            console.error("Error deleting subcategory:", error);
            alert("Failed to delete subcategory.");
        }
    };

    const handleVisibilityChange = (productId, currentVisibility) => {
            axios
                .put(`https://api.vivekflowers.com/api/subcategory/visibility/${productId}`, {
                    visibility: currentVisibility === 1 ? 0 : 1,
                })
                .then((response) => {
                    alert(response.data.message);
                    fetchSubcategories(selectedCategory);
                })
                .catch((error) => console.error('Error updating visibility:', error));
        };


    const handlePageChange = (e, page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        if (selectedCategory) {
            fetchSubcategories(selectedCategory);
        }
    }, [selectedCategory, currentPage, rowsperpage, search]);



    return (

        <div className="container-scroller">

            <Header />

            <div className="container-fluid page-body-wrapper">

                <Sidebar />

                <div className="main-panel">

                    <div className="content-wrapper">

                        <div className="row">

                            <div className="col-lg-12 grid-margin stretch-card">

                                <div className="card">

                                    <div className="card-body">

                                        <div className="d-flex justify-content-between align-items-center">

                                            <h4 className="card-title">Subcategories</h4>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label for="exampleInputName1">Category</label>
                                                    <select type="text" className="form-select" name="category_id" value={selectedCategory} onChange={handleCategoryChange}>
                                                        <option value="" disabled>Select the Category</option>
                                                        {categories.map((category) => (
                                                            <option key={category.id} value={category.id}>
                                                                {category.name}
                                                            </option>
                                                        ))}

                                                    </select>
                                                </div>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control w-25 responsive-search-bar"
                                                placeholder="Search subcategories..."
                                                value={search}
                                                onChange={handleSearchChange}
                                                style={{ maxWidth: "300px", height: '40px' }}
                                            />
                                        </div>

                                        <div className="table-responsive mt-4">

                                            <table className="table">

                                                <thead>

                                                    <tr>
                                                        <th>Sub Category Name</th>
                                                        <th>Description</th>
                                                        <th>Options</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {subcategories.map((product, index) => (
                                                        <tr key={product.id}>
                                                            <td className="py-1">
                                                                <img src={`https://api.vivekflowers.com/${product.image_url}`} alt={product.name} style={{ marginRight: "10px" }} />

                                                                {product.name}
                                                            </td>
                                                            <td>{(product.description).slice(0, 80)}...</td>
                                                            <td>
                                                            {product.visibility === 0 && (
                                                                    <label className="badge badge-danger pointer" style={{ marginRight: "10px" }} onClick={() => handleVisibilityChange(product.id, product.visibility)} >
                                                                        <i className="mdi mdi-eye-off" style={{ fontSize: "18px" }}></i>
                                                                    </label>
                                                                )}
                                                                {product.visibility === 1 && (
                                                                    <label className="badge badge-primary pointer" style={{ marginRight: "10px" }} onClick={() => handleVisibilityChange(product.id, product.visibility)} >
                                                                        <i className="mdi mdi-eye" style={{ fontSize: "18px" }}></i>
                                                                    </label>
                                                                )}
                                                                <label className="badge badge-danger pointer" style={{ marginRight: "10px" }}  onClick={() => handleDelete(product.id)}>

                                                                    <i className="mdi mdi-delete" style={{ fontSize: "18px" }}></i>
                                                                </label>

                                                                <label className="badge badge-secondary pointer">

                                                                    <i a className="mdi mdi-storefront-edit" onClick={() => handleEdit(product)} style={{ fontSize: "18px" }}></i>

                                                                </label>

                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                            {/* Pagination and Rows per Page */}
                                            <div className="d-flex flex-wrap justify-content-between align-items-center p-2">

                                                <FormControl variant="outlined" size="small" style={{ marginRight: '10px', marginBottom: '10px', minWidth: '80px' }} >
                                                    <Select labelId="rows-per-page-label" value={rowsperpage} onChange={(e) => { setRowsPerPage(e.target.value); setCurrentPage(1); }}>
                                                        <MenuItem value={5}>5</MenuItem>
                                                        <MenuItem value={10}>10</MenuItem>
                                                        <MenuItem value={15}>15</MenuItem>
                                                        <MenuItem value={25}>25</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <Pagination
                                                    count={totalPages} // Ensure this comes from the API correctly
                                                    page={currentPage}
                                                    onChange={(e, page) => handlePageChange(e, page)}
                                                    variant="outlined"
                                                    color="primary"
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default SubCategory;
