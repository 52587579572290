import React, { useEffect, useState } from 'react';
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import axios from 'axios';
import { Pagination, Select, MenuItem, FormControl } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Panchangs = () => {

    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);

    const [panchangs, setPanchangs] = useState([]);
    const [filteredPanchangs, setFilteredPanchangs] = useState([]);
    const [search, setSearch] = useState('');
    const [rowsperpage, setRowsPerPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
   
    const handleEdit = (panchang) => {
        navigate("/editpanchangs", { state: { panchang } });
    };

    const handleRemove = (id, currentRemoveStatus) => {
        axios
            .put(`https://api.vivekflowers.com/api/panchang/remove/${id}`, {
                remove: currentRemoveStatus === 1 ? 0 : 1,
            })
            .then((response) => {
                alert(response.data.message || "Panchang Deleted Successfully");
                fetchPanchangs(currentPage);
            })
            .catch((error) => console.error('Error updating remove status:', error));
    };

    const fetchPanchangs = (page = 1) => {
        axios
            .get(`https://api.vivekflowers.com/api/panchang?page=${page}&per_page=${rowsperpage}`)
            .then((response) => {
                const data = response.data.panchang || [];
                setPanchangs(data);
                setFilteredPanchangs(data); 
                setTotalPages(response.data.pagination.total_pages || 1);
            })
            .catch((error) => console.error('Error fetching products:', error));
    };

    const handlePageChange = (e, page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        const filtered = panchangs.filter((panchang) =>
            Object.values(panchang)
                .join(' ')
                .toLowerCase()
                .includes(search.toLowerCase())
        );
        setFilteredPanchangs(filtered);
    }, [search, panchangs]);

    useEffect(() => {
        fetchPanchangs(currentPage);
    }, [currentPage, rowsperpage]);

    return (
        <div className="container-scroller">
            <Header />

            <div className="container-fluid page-body-wrapper">
                <Sidebar />

                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="card-title">Panchangs</h4>

                                            <input
                                                type="text"
                                                className="form-control w-25 responsive-search-bar"
                                                placeholder="Search panchangs..."
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                                style={{ maxWidth: "300px", height: '40px' }}
                                            />
                                        </div>

                                        <div className="table-responsive mt-4">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Date</th>
                                                        <th>Sun Rise</th>
                                                        <th>Sun Set</th>
                                                        <th>Rahukal</th>
                                                        <th>YamaGanda</th>
                                                        <th>Tidi</th>
                                                        <th>Paksham</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredPanchangs.map((panchang, index) => (
                                                        <tr key={index}>
                                                            <td>{panchang.id}</td>
                                                            <td>{panchang.date}</td>
                                                            <td>{panchang.sunrise}</td>
                                                            <td>{panchang.sunset}</td>
                                                            <td>{panchang.rahukal}</td>
                                                            <td>{panchang.yamaganda}</td>
                                                            <td>{panchang.tidi}</td>
                                                            <td>{panchang.paksham}</td>
                                                            <td>
                                                                <label
                                                                    className="badge badge-danger pointer"
                                                                    style={{ marginRight: "10px" }}
                                                                >
                                                                    <i
                                                                        className="mdi mdi-delete"
                                                                        style={{ fontSize: "18px" }}
                                                                        onClick={() => handleRemove(panchang.id, panchang.remove)}
                                                                    ></i>
                                                                </label>

                                                                <label className="badge badge-secondary pointer">
                                                                    <i
                                                                        className="mdi mdi-storefront-edit"
                                                                        onClick={() => handleEdit(panchang)}
                                                                        style={{ fontSize: "18px" }}
                                                                    ></i>
                                                                </label>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        {/* Pagination and Rows per Page */}
                                        <div className="d-flex flex-wrap justify-content-between align-items-center p-2">
                                            <FormControl
                                                variant="outlined"
                                                size="small"
                                                style={{ marginRight: '10px', marginBottom: '10px', minWidth: '80px' }}
                                            >
                                                <Select
                                                    labelId="rows-per-page-label"
                                                    value={rowsperpage}
                                                    onChange={(e) => {
                                                        setRowsPerPage(e.target.value);
                                                        setCurrentPage(1);
                                                    }}
                                                >
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <Pagination
                                                count={totalPages}
                                                page={currentPage}
                                                onChange={handlePageChange}
                                                variant="outlined"
                                                color="primary"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Panchangs;
