import React, { useEffect, useState } from 'react';
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import axios from 'axios';
import { Pagination, Select, MenuItem, FormControl, FormControlLabel, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Gallery = () => {

    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);

    const [gallery, setGallery] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
  


    const fetchGallery = (page = 1) => {
        axios
            .get(`https://api.vivekflowers.com/api/gallery?page=${page}&per_page=${rowsPerPage}`)
            .then((response) => {
                setGallery(response.data.gallery || []);
                setTotalPages(Math.ceil(response.data.length / rowsPerPage));
            })
            .catch((error) => console.error('Error fetching gallery:', error));
    };


    const handleRemove = (id, currentRemoveStatus) => {
        axios
            .put(`https://api.vivekflowers.com/api/gallery/remove/${id}`, {
                remove: currentRemoveStatus === 1 ? 0 : 1,
            })
            .then((response) => {
                alert(response.data.message);
                fetchGallery();
            })
            .catch((error) => console.error('Error updating remove status:', error));
    };

    const handlePageChange = (e, page) => {
        setCurrentPage(page);
    };

    const handleEdit = (gallery) => {
        navigate("/editgallery", { state: { gallery } });
    };


    useEffect(() => {
        fetchGallery(currentPage);
    }, [currentPage, rowsPerPage]);

    const handleDisplayChange = (id, newOrder) => {
        const currentOrder = gallery.find(product => product.id === id).displayOrder;
        if (currentOrder === newOrder) return;

        if (newOrder < 1 || newOrder > 10) {
            alert("Display order should be between 1 and 10.");
            return;
        }

        let updatedGallery = [...gallery];

        if (newOrder < currentOrder) {
            updatedGallery.forEach(product => {
                if (product.displayOrder >= newOrder && product.displayOrder < currentOrder) {
                    product.displayOrder += 1;
                }
            });
        } else if (newOrder > currentOrder) {
            updatedGallery.forEach(product => {
                if (product.displayOrder <= newOrder && product.displayOrder > currentOrder) {
                    product.displayOrder -= 1;
                }
            });
        }

        const updatedProductIndex = updatedGallery.findIndex(product => product.id === id);
        updatedGallery[updatedProductIndex].displayOrder = newOrder;
        setGallery(updatedGallery);

        axios
            .put(`https://api.vivekflowers.com/api/gallery/displayOrder/${id}`, {
                displayOrder: newOrder,
            })
            .then((response) => console.log('Display order updated successfully', response))
            .catch((error) => console.error('Error updating display order:', error));
    };



    const handleToggleEnable = (id, enabled) => {
        console.log('Toggling enable for ID:', id, 'to:', enabled);
        const newEnableStatus = enabled === "1" ? "0" : "1";
        console.log('newenablestatus',newEnableStatus)
        setGallery((prevGallery) => prevGallery.map((product) => product.id === id ? { ...product, enable: newEnableStatus } : product
        ))
        axios
            .put(`https://api.vivekflowers.com/api/gallery/enable/${id}`, {
                enable: newEnableStatus,
            })
            .then((response) => console.log('Enable status updated successfully', response))
            .catch((error) => console.error('Error updating enable status:', error));
    }


    return (
        <div className="container-scroller">

            <Header />

            <div className="container-fluid page-body-wrapper">

                <Sidebar />

                <div className="main-panel">

                    <div className="content-wrapper">

                        <div className="row">

                            <div className="col-lg-12 grid-margin stretch-card">

                                <div className="card">

                                    <div className="card-body">

                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="card-title">Gallery</h4>
                                            <a href='/addgallery'><button
                                                className="btn btn-primary"
                                                style={{ padding: '10px 20px', borderRadius: '4px' }}
                                            >
                                                Add Gallery
                                            </button></a>
                                        </div>


                                        <div className="table-responsive mt-4">

                                            <table className="table">

                                                <thead>

                                                    <tr>
                                                        <th>Icons</th>
                                                        <th>Name</th>
                                                        <th>Display Order</th>
                                                        <th>Enabled</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {gallery.map((product, index) => (
                                                        <tr key={index}>
                                                            <td className="py-1">
                                                                <img
                                                                    src={`https://api.vivekflowers.com/uploads/${product.icon}`}
                                                                    alt={product.name}
                                                                    style={{ width: '250px', height: 'auto', marginRight: '10px', borderRadius: '0' }}
                                                                />
                                                            </td>
                                                            <td>{product.name}</td>
                                                            <td>
                                                                <select value={product.displayOrder} class="form-select"
                                                                    onChange={(e) => handleDisplayChange(product.id, parseInt(e.target.value))}>
                                                                    {[...Array(10).keys()].map((num) => (
                                                                        <option key={num + 1} value={num + 1}>{num + 1}</option>
                                                                    ))}

                                                                </select>
                                                            </td>
                                                            <td>
                                                            <FormControlLabel
                                                                 control={
                                                                     <Switch 
                                                                         checked={product.enable === "1" ? true : false} 
                                                                         onChange={(e) => handleToggleEnable(product.id, product.enable)} 
                                                                         color="primary" 
                                                                     />
                                                                    }
                                                                    label={product.enable === "1" ? 'Yes' : 'No'} 
                                                                    />
                                                             </td>


                                                            <td>

                                                                <label className="badge badge-danger pointer" style={{ marginRight: "10px" }} onClick={() => handleRemove(product.id, product.remove)} >
                                                                    <i className="mdi mdi-delete" style={{ fontSize: "18px" }}></i>
                                                                </label>
                                                                <label className="badge badge-secondary pointer">

                                                                    <i a className="mdi mdi-storefront-edit" onClick={() => handleEdit(product)} style={{ fontSize: "18px" }}></i>

                                                                </label>



                                                            </td>


                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex flex-wrap justify-content-between align-items-center p-2">

                                            <FormControl variant="outlined" size="small" style={{ marginRight: '10px', marginBottom: '10px', minWidth: '80px' }} >
                                                <Select labelId="rows-per-page-label" value={rowsPerPage} onChange={(e) => { setRowsPerPage(e.target.value); setCurrentPage(1); }}>
                                                    <MenuItem value={5}>5</MenuItem>
                                                    <MenuItem value={10}>10</MenuItem>
                                                    <MenuItem value={15}>15</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} variant="outlined" color="primary" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end of the gallery */}
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Gallery;
