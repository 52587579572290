import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import { useNavigate, useLocation } from "react-router-dom";

const EditPost = () => {

    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);

    const location = useLocation();
    const post = location.state?.posts;
    
    const [postData, setPostData] = useState(post || {});
    const [selectedImage, setSelectedImage] = useState(post?.image || null);

    useEffect(() => {
        if (post) {
            setPostData(post);
            setSelectedImage(post.image);
        }
    }, [post]);


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setPostData((prevState) => ({ ...prevState, image: file }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        if (postData.image instanceof File) {
            formData.append("image", postData.image);
        }

        axios
            .put(`https://api.vivekflowers.com/api/image/${postData.id}`, formData)
            .then((response) => {
                console.log("Post updated successfully:", response.data);
                navigate("/gallery");
            })
            .catch((error) => {
                console.error("Error updating post:", error);
            });
    };

    return (
        <div className="container-scroller">
            <Header />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Edit Post</h4>
                                <form onSubmit={handleSubmit}>
                                    
                                    
                                    <div className="form-group">
                                        <label>Image</label>
                                        <input type="file" name="image" onChange={handleFileChange} className="form-control" />
                                    </div>
                                    <button type="submit" className="btn btn-primary">Update Post</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
    );
};

export default EditPost;
