import React, { useEffect, useState } from 'react';
import Header from '../../includes/navBar';
import Sidebar from '../../includes/sideBar';
import Footer from '../../includes/footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AddBanner = () => {
    
    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);


    const [postData, setPostData] = useState({
        image: "",
        enable: 1,
        name: "",
        subcategory_id: []
    });

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [subcategories, setSubcategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loadingSubcategories, setLoadingSubcategories] = useState(false);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get("https://api.vivekflowers.com/api/category");
                setCategories(response.data);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        fetchCategories();
    }, []);

    useEffect(() => {
        if (selectedCategory) {
            const fetchSubcategories = async () => {
                setLoadingSubcategories(true);
                try {
                    const response = await axios.get(`https://api.vivekflowers.com/api/subcategory/${selectedCategory}`);
                    setSubcategories(response.data);
                } catch (error) {
                    console.error("Error fetching subcategories:", error);
                } finally {
                    setLoadingSubcategories(false);
                }
            };

            fetchSubcategories();
        } else {
            setSubcategories([]);
        }
    }, [selectedCategory]);

    const handleFileChange = (e) => {
        setPostData({ ...postData, image: e.target.files[0] });
    };

    const handleChange = (e) => {
        setPostData({ ...postData, [e.target.name]: e.target.value });
    };

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
        // Clear selected subcategories when category changes
        setPostData(prev => ({ ...prev, subcategory_id: [] }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("image", postData.image);
        formData.append("enable", Number(postData.enable));
        formData.append("name", postData.name);
        
        formData.append("subcategory_id", `[${postData.subcategory_id.join(',')}]`);
        
        try {
            const response = await axios.post("https://api.vivekflowers.com/api/add-banner", formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });

            alert("Banner added successfully!");
            navigate(`/banner`);

            setPostData({
                image: "",
                enable: 1,
                name: "",
                subcategory_id: []
            });
        } catch (error) {
            console.error("Error adding banner:", error);
            alert("Failed to add banner. Please try again.");
        }
    };

    const handleCancel = () => {
        setPostData({
            image: "",
            enable: 1,
            name: "",
            subcategory_id: []
        });
        setSelectedCategory("");
    };

    const handleSubcategorySelection = (id) => {
        setPostData(prevState => {
            const selectedSubcategories = prevState.subcategory_id.includes(id)
                ? prevState.subcategory_id.filter(subId => subId !== id) 
                : [...prevState.subcategory_id, id]; 
    
            return { ...prevState, subcategory_id: selectedSubcategories };
        });
    };

    return (
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper">
                <Header />
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-12 grid-margin stretch-card">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h4 className="card-title">Add Banner</h4>

                                                    <form className="forms-sample" onSubmit={handleSubmit}>
                                                        <div className="row">
                                                            {/* Banner Name */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="name">Banner Name</label>
                                                                    <input
                                                                        type="text"
                                                                        name="name"
                                                                        className="form-control"
                                                                        value={postData.name}
                                                                        onChange={handleChange}
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>

                                                            {/* Image Upload */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="image">Upload Image</label>
                                                                    <input
                                                                        type="file"
                                                                        name="image"
                                                                        className="form-control"
                                                                        onChange={handleFileChange}
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>

                                                            {/* Enable Status */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="enable">Enable</label>
                                                                    <select
                                                                        name="enable"
                                                                        className="form-control"
                                                                        value={postData.enable}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option value="1">Yes</option>
                                                                        <option value="0">No</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            {/* Category Selection */}
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="category">Category</label>
                                                                    <select
                                                                        name="category"
                                                                        className="form-control"
                                                                        value={selectedCategory}
                                                                        onChange={handleCategoryChange}
                                                                        required
                                                                    >
                                                                        <option value="">Select a category</option>
                                                                        {categories.map(category => (
                                                                            <option key={category.id} value={category.id}>
                                                                                {category.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            {/* Subcategory Selection */}
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label>Subcategories</label>
                                                                    {selectedCategory ? (
                                                                        <>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Search subcategories..."
                                                                                value={searchTerm}
                                                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                                                disabled={loadingSubcategories}
                                                                            />
                                                                            {loadingSubcategories ? (
                                                                                <p>Loading subcategories...</p>
                                                                            ) : (
                                                                                <div style={{ maxHeight: "200px", overflowY: "auto", border: "1px solid #ccc", borderRadius: "5px", padding: "5px", marginTop: "5px" }}>
                                                                                    <ul className="list-group">
                                                                                        {subcategories
                                                                                            .filter(sub => sub.name.toLowerCase().includes(searchTerm.toLowerCase()))
                                                                                            .map(sub => (
                                                                                                <li key={sub.id} className="list-group-item">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        checked={postData.subcategory_id.includes(sub.id)}
                                                                                                        onChange={() => handleSubcategorySelection(sub.id)}
                                                                                                    />
                                                                                                    {" " + sub.name}
                                                                                                </li>
                                                                                            ))}
                                                                                    </ul>
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <p>Please select a category first</p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Buttons */}
                                                        <button type="submit" className="btn btn-primary btn-icon-text mr-2">
                                                            <i className="ti-file btn-icon-prepend"></i> Submit
                                                        </button>
                                                        <button
                                                            onClick={handleCancel}
                                                            type="button"
                                                            className="btn btn-danger btn-icon-text"
                                                            style={{ marginLeft: '10px', height: '45px', width: '120px' }}
                                                        >
                                                            <i className="ti-close btn-icon-prepend"></i> Cancel
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddBanner;