import React from "react";
import './loading.css';

const Deleted = () => {


  return (
    <div id="loading-overlay">
      <div class="loader"></div>
    </div>
  );
};

export default Deleted;
