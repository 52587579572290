import React, { useEffect, useState } from 'react';
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";



const AddProduct = () => {

    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);

    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
   

    const [productData, setProductData] = useState({
        p_id: "",
        SKU: "",
        productName: "",
        amount: "",
        category_id: "",
        subcategory_id: "",
        description: "",
        shortdescription: "",
        weight: "",
        weightWithIce: "",
        visibility: 1,
        image: null,
    });
    console.log(productData);

    useEffect(() => {
        axios
            .get("https://api.vivekflowers.com/api/category")
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => console.error("Error fetching categories:", error));
    }, []);

    useEffect(() => {
        if (productData.category_id) {
            axios
                .get(`https://api.vivekflowers.com/api/subcategory/${productData.category_id}`)
                .then((response) => {
                    setSubcategories(response.data);
                })
                .catch((error) => console.error("Error fetching subcategories:", error));
        } else {
            setSubcategories([]);
        }
    }, [productData.category_id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProductData({
            ...productData, [name]: value,

        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        console.log("Selected File:", file);
        setProductData((prevData) => ({ ...prevData, image: file, }));

    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();

        // Ensure correct data types
        formData.append("p_id", parseInt(productData.p_id));
        formData.append("SKU", String(productData.SKU)); 
        formData.append("productName", String(productData.productName)); 
        formData.append("amount", parseFloat(productData.amount)); 
        formData.append("category_id", Number(productData.category_id)); 
        formData.append("subcategory_id", Number(productData.subcategory_id)); 
        formData.append("description", String(productData.description)); 
        formData.append("shortdescription", String(productData.shortdescription)); 
        formData.append("weight", parseFloat(productData.weight)); 
        formData.append("weightWithIce", parseFloat(productData.weightWithIce));
        formData.append("visibility", Number(productData.visibility)); 

        // Append image (Check if exists)
        if (productData.image instanceof File) {
            formData.append("image", productData.image);
        } else {
            console.error("No valid image selected");
        }

        // Debugging: Check appended values
        for (let pair of formData.entries()) {
            console.log(pair[0] + ": ", pair[1]);
        }

        try {
            const response = await axios.post(
                "https://api.vivekflowers.com/api/product",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            console.log("Response:", response.data);
            toast.success("Product  Added successfully!", {
                                position: "top-right",
                            });
                
                            setTimeout(() => {
                                navigate("/products");
                            }, 5000);;

            // Reset form data
            setProductData({
                p_id: "",
                SKU: "",
                productName: "",
                amount: "",
                category_id: "",
                subcategory_id: "",
                description: "",
                shortdescription: "",
                weight: "",
                weightWithIce: "",
                visibility: 1,
                image: null,
            });
        } catch (error) {
            console.error("Error adding product:", error.response?.data || error);
            toast.error("Failed to add product. Please try again.");
        }
    };


    const handleCancel = () => {
        setProductData({
            SKU: '',
            productName: '',
            p_id: '',
            amount: '',
            description: '',
            shortdescription: '',
            category_id: '',
            subcategory_id: '',
            weight: '',
            weightWithIce: "",
            visibility: 1,
            image: "",
        });
    }

    return (
        <div className="container-scroller">

            <div className="container-fluid page-body-wrapper">

                <Header />

                <Sidebar />

                <div className="main-panel">

                    <div className="content-wrapper">

                        <div className="row">

                            <div className="col-lg-12 grid-margin stretch-card">

                                <div className="card">

                                    <div className="card-body">

                                        <div className="col-12 grid-margin stretch-card">

                                            <div className="card">

                                                <div className="card-body">

                                                    <h4 className="card-title">Add Product</h4>

                                                    <form className="forms-sample" onSubmit={handleSubmit}>

                                                        <div className="row">

                                                            {/* SKU ID */}

                                                            <div className="col-md-4">

                                                                <div className="form-group">

                                                                    <label htmlFor="SKU">SKU</label>

                                                                    <input type="text" className="form-control" name="SKU" placeholder='Enter SKU...' value={productData.SKU} onChange={handleChange} required />

                                                                </div>

                                                            </div>

                                                            {/* Product Name */}
                                                            <div className="col-md-4">

                                                                <div className="form-group">

                                                                    <label htmlFor="productName">Product Name</label>

                                                                    <input type="text" className="form-control" name="productName" value={productData.productName} onChange={handleChange} placeholder="Enter Product Name..." required />

                                                                </div>
                                                            </div>

                                                            {/* Product ID */}

                                                            <div className="col-md-4">

                                                                <div className="form-group">

                                                                    <label htmlFor="p_id">Product ID</label>

                                                                    <input type="text" className="form-control" name="p_id" value={productData.p_id} onChange={handleChange} placeholder="Enter Product ID..." required />

                                                                </div>
                                                            </div>

                                                            {/* Amount */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="amount">Amount</label>
                                                                    <input type="number" className="form-control" name="amount" value={productData.amount} onChange={handleChange} placeholder="Enter Amount..." required />

                                                                </div>
                                                            </div>

                                                            {/* Description */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="description">Description</label>
                                                                    <input type="text" className="form-control" name="description" value={productData.description} onChange={handleChange} placeholder="Enter Description..." required />

                                                                </div>
                                                            </div>

                                                            {/* Short Description */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="shortdescription">Short Description</label>
                                                                    <input type="text" className="form-control" name="shortdescription" value={productData.shortdescription} onChange={handleChange} placeholder="Enter Short Description..." required />

                                                                </div>
                                                            </div>
                                                            {/* end of the short description */}

                                                            {/* Weight */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="weight">Weight (in pounds)</label>
                                                                    <input type="number" className="form-control" name="weight" value={productData.weight} onChange={handleChange} placeholder="Enter Weight in pounds..." required />

                                                                </div>
                                                            </div>
                                                            {/* Weight end */}

                                                            {/* ice Weight */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="weightWithIce">Ice Weight</label>
                                                                    <input type="number" className="form-control" name="weightWithIce" value={productData.weightWithIce} onChange={handleChange} placeholder="Enter Ice Weight..." required />

                                                                </div>
                                                            </div>
                                                            {/* ice Weight end */}

                                                            {/* category id */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label for="exampleInputName1">Category</label>
                                                                    <select type="text" className="form-select" name="category_id" placeholder="Enter Category..." value={productData.category_id}
                                                                        onChange={handleChange}>
                                                                        <option value="" disabled>Select the Category</option>
                                                                        {categories.map((category) => (
                                                                            <option key={category.id} value={category.id}>
                                                                                {category.name}
                                                                            </option>
                                                                        ))}

                                                                    </select>
                                                                </div>
                                                            </div>
                                                            {/* category id end */}

                                                            {/* Subcategory ID */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label for="exampleInputName1">Sub Category</label>
                                                                    <select type="text" className="form-select" name="subcategory_id" placeholder="Enter Category..." value={productData.subcategory_id}
                                                                        onChange={handleChange}>
                                                                        <option value="" disabled>Select the Category</option>
                                                                        {subcategories.map((subcategory) => (
                                                                            <option key={subcategory.id} value={subcategory.id}>
                                                                                {subcategory.name}
                                                                            </option>
                                                                        ))}

                                                                    </select>
                                                                </div>
                                                            </div>
                                                            {/* subcategory end */}

                                                            {/* Image Upload */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="image">Upload Image</label>
                                                                    <input
                                                                        type="file"
                                                                        name="image"
                                                                        className="form-control"
                                                                        onChange={handleFileChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <button type="submit" className="btn btn-primary btn-icon-text mr-2">
                                                            <i className="ti-file btn-icon-prepend"></i> Submit
                                                        </button>
                                                        <button onClick={handleCancel}
                                                            type="button"
                                                            className="btn btn-primary btn-icon-text"
                                                            style={{ marginLeft: '10px', height: '45px', width: '120px' }}>
                                                            <i className="ti-close btn-icon-prepend"></i>

                                                            Cancel
                                                        </button>
                                                    </form>
                                                    <ToastContainer />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default AddProduct;
