import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import { useNavigate } from 'react-router-dom';


const Orders = () => {

    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);

    const [items, setItems] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    const fetchOrders = async () => {
        try{
            const response = await axios.get(`https://api.vivekflowers.com/api/weekly-orders`,{
                params: {
                    startDate: startDate,
                    endDate: endDate
                  }
            });

           // console.log(response.data);

            const productQuantities = [];

            response.data.orders.forEach(order => {
                order.order_details.forEach(item => {
                    
                    const existingProduct = productQuantities.find(product => product.productId === item.product_id);
            
                    if (existingProduct) {
                        existingProduct.qty += item.quantity;
                    } else {
                        productQuantities.push({
                            productName: item.product_name,
                            productId: item.product_id,
                            qty: item.quantity
                        });
                    }
                });
            });

            setItems(productQuantities);

        }catch(error){
            console.error(error);
        }
          
    };


  
    useEffect(() => {
        fetchOrders();
    }, []);
    
   // console.log(startDate);

    return (
        <div className="container-scroller">
            <Header />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">

                                    {items ?

                                      <>

                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="card-title">Orders</h4>
                                            <div className="d-flex">
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={startDate}
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                    style={{marginRight: '10px'}}
                                                />
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={endDate}
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                    style={{marginRight: '10px'}}
                                                />

                                                <button class="btn btn-warning" onClick={fetchOrders}>Find</button>
                                            </div>
                                        </div>

                                        <div className="table-responsive mt-4">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Product ID</th>
                                                        <th>Product Name</th>
                                                        <th>QTY</th>
                                                       
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {items && items.map((item, index) => (
                                                        <tr key={index}>
                                                           <td>{item.productId}</td>
                                                           <td>{item.productName}</td>
                                                           <td>{item.qty}</td>
                                                        </tr>
                                                    ))} 
                                                </tbody>
                                            </table>
                                        </div>
                                        </>
                                        :
                                        <div className="d-flex justify-content-center align-items-center" style={{minHeight: '80vh', textAlign: 'center'}}>
                                            <div style={{maxWidth: '800px'}}> 
                                             
                                                <label>Start Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={startDate}
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                    style={{marginBottom: '10px'}}
                                                />
                                                 <label>End Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={endDate}
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                    style={{marginBottom: '10px'}}
                                                />

                                                <button class="btn btn-warning" onClick={fetchOrders}>Get Items</button>
                                            </div>
                                        </div>
                                                }
                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Orders;
