

const Footer = () => {

    return (

 <footer class="footer">
  <div class="d-sm-flex justify-content-center justify-content-sm-between">
    <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © {new Date().getFullYear()}. Vivek Flowers. All rights reserved.</span>
    <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Web Solutions by <a href="https://sninfoserv.com/" target="_blank">SN Infoserv</a></span>
  </div>
</footer>

    );
};

export default Footer;