import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import { useNavigate, useLocation } from "react-router-dom";

const EditSubCategory = () => {

    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);

    const location = useLocation();
    const subcategories = location.state?.subcategories;

    const [formData, setFormData] = useState({
        id: subcategories.id || "",
        category_id: subcategories.category_id || "",
        name: subcategories.name || "",
        image: subcategories.image || "",
        description: subcategories.description || ""
    });

    const [categories, setCategories] = useState([]);
    console.log("form data", formData);
    useEffect(() => {
        axios
            .get('https://api.vivekflowers.com/api/category')
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.error('Error fetching categories:', error);
            });
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData((prevState) => ({ ...prevState, image: file }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { id, image_url, ...updatedSubcategories } = formData;
        console.log("updated ", updatedSubcategories);
        const formDataToSubmit = new FormData();
        formDataToSubmit.append('name', updatedSubcategories.name);
        formDataToSubmit.append('category_id', updatedSubcategories.category_id);
        formDataToSubmit.append('description', updatedSubcategories.description);

        if (updatedSubcategories.image instanceof File) {
            formDataToSubmit.append('image', updatedSubcategories.image);
        }
        console.log("submit", formDataToSubmit);
        axios
            .put(`https://api.vivekflowers.com/api/subcategory?id=${id}`, formDataToSubmit, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
            .then((response) => {
                alert(response.data.message || "Subcategory updated successfully.");
                navigate("/subcategory");
            })
            .catch((error) => {
                console.error("Error updating Subcategory:", error);
                alert("Failed to update Subcategory. Please try again.");
            });
    };

    const handleCancel = () => {
        navigate("/subcategory");
    };

    return (
        <>
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper">
                    <Header />
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-lg-12 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="col-12 grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h4 className="card-title">Edit Subcategory</h4>
                                                        <form className="forms-sample" onSubmit={handleSubmit}>
                                                            <div className="row">
                                                                {/* Category */}
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="category_id">Category</label>
                                                                        <select
                                                                            className="form-select"
                                                                            name="category_id"
                                                                            value={formData.category_id}
                                                                            onChange={handleChange}
                                                                            required
                                                                        >
                                                                            <option value="" disabled>
                                                                                Select the Category
                                                                            </option>
                                                                            {categories.map((category) => (
                                                                                <option key={category.id} value={category.id}>
                                                                                    {category.name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                {/* Subcategory Name */}
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="name">Subcategory Name</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="name"
                                                                            value={formData.name}
                                                                            onChange={handleChange}
                                                                            placeholder="Enter Subcategory Name"
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {/* Description */}
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="description">Description</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="description"
                                                                            value={formData.description}
                                                                            onChange={handleChange}
                                                                            placeholder="Enter Description"
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {/* Image Upload */}
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="image">Upload Image</label>
                                                                        <input
                                                                            type="file"
                                                                            className="form-control"
                                                                            onChange={handleFileChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Buttons */}
                                                            <button type="submit" className="btn btn-primary btn-icon-text mr-2">
                                                                <i className="ti-file btn-icon-prepend"></i> Submit
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={handleCancel}
                                                                className="btn btn-primary btn-icon-text"
                                                                style={{ marginLeft: '10px' }}
                                                            >
                                                                <i className="ti-close btn-icon-prepend"></i> Cancel
                                                            </button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditSubCategory;
