
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import { useEffect, useRef, useState } from 'react';
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useNavigate } from 'react-router-dom';
import Chart from "chart.js/auto";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


const Dashboard = () => {

  const user = sessionStorage.getItem('VVKADuser') || 0;
  const userName = sessionStorage.getItem('VVKADuserName') || 0;
  const navigate = useNavigate();
  useEffect(() => {
    if (!user || user <= 0) {
      navigate('/');
    }
  }, [user]);
  

  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentDateTime(new Date());
      }, 1000);
  
      return () => clearInterval(intervalId);
    }, []); 
  
    const hours = currentDateTime.getHours();
    const minutes = currentDateTime.getMinutes();
    const seconds = currentDateTime.getSeconds();
    const amPm = hours >= 12 ? 'PM' : 'AM';

    
    const formattedHours = hours % 12 || 12;  // Converts hour to 12-hour format
    const formattedMinutes = minutes.toString().padStart(2, '0'); // Ensure two digits for minutes
    const formattedSeconds = seconds.toString().padStart(2, '0');


  const [reportData, setReportData] = useState({
    total_sales: 0,
    total_orders: 0,
    monthly_sales: 0,
    monthly_orders: 0,
    todays_orders: 0
  });
  const [orderSummary, setOrderSummary] = useState({
    totalSales: 0,
    totalOrders: 0,
    uniqueCustomerPercentage: 0,
  })

  const [salesChartData, setSalesChartData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const chartRef = useRef(null);
  const statusRef = useRef(null);

  

  //Fetching Report

  useEffect(() => {
    const fetchReport = async () => {

      try {
        const response = await fetch('https://api.vivekflowers.com/api/report');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }
        const data = await response.json();
        setReportData(data);
      } catch (err) {
        console.error("Error fetching report:", err)
      }
    }
    fetchReport();

  }, []);
  // Sales Data
  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const response = await fetch("https://api.vivekflowers.com/api/sale-report");
        if (!response.ok) throw new Error("API request failed");
        const data = await response.json();

        const labels = data.map((item) => item.month);
        const sales = data.map((item) => item.sales);

        setSalesChartData({
          labels,
          datasets: [
            {
              label: 'Monthly Sales',
              data: sales,
              backgroundColor: "rgba(54, 162, 235, 0.5)",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 1,
              hoverBackgroundColor: '#34495e',
              hoverBorderColor: '#ecf0f1',
            },
          ],
        });
      } catch (error) {
        console.error("Error Fetching Sales Data:", error);
      }
    };

    fetchSalesData();
  }, []);



  useEffect(() => {
    let chartInstance;

    const fetchStorePickup = async () => {
      try {
        const response = await fetch("https://api.vivekflowers.com/api/ordertype-report");
        if (!response.ok) throw new Error("API request failed");

        const data = await response.json();

        const firstMonthKey = Object.keys(data)[0];
        const { delivery, pickup } = data[firstMonthKey];

        const deliveredOrders = delivery.order_count;
        const pickupOrders = pickup.order_count;

        const deliveredSales = delivery.sales_total;
        const pickupSales = pickup.sales_total;

        if (chartInstance) {
          chartInstance.destroy();
        }

        if (chartRef.current) {
          chartInstance = new Chart(chartRef.current, {
            type: "doughnut",
            data: {
              labels: ["Delivery", "Pickup"],
              datasets: [
                {
                  label: "Order Count",
                  data: [deliveredOrders, pickupOrders],
                  backgroundColor: ["#4caf50", "#2196f3"],
                  hoverBackgroundColor: ["#2e7d32", "#1565c0"],
                  borderWidth: 2,
                  borderColor: "#ffffff",
                },
              ],
            },
            options: {
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                  labels: {
                    font: {
                      size: 14,
                    },
                    color: "#34495e",
                  },
                },
                tooltip: {
                  callbacks: {
                    label: (tooltipItem) => {
                      const index = tooltipItem.dataIndex;
                      const labels = ["Delivered", "Pickup"];
                      const counts = [deliveredOrders, pickupOrders];
                      const sales = [deliveredSales, pickupSales];

                      return `${labels[index]}: ${counts[index]} orders, ₹${sales[index]} total sales`;
                    },
                  },
                },
              },
              layout: {
                padding: {
                  top: 20,
                  bottom: 20,
                },
              },
            },
          });
        }
      } catch (error) {
        console.error("Error Fetching Sales Data:", error);
      }
    };

    fetchStorePickup();
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, []);


  // order type

  useEffect(() => {
    const fetchOrderStatus = async () => {
      try {
        const response = await fetch('https://api.vivekflowers.com/api/orderstatus-report');
        if (!response.ok) throw new Error("API request failed");

        const data = await response.json();

        // Extract status keys dynamically
        const statusLabels = Object.keys(data);
        const statusCounts = statusLabels.map(status => data[status].order_count || 0);

        // Set dynamic chart data
        if (statusRef.current) {
          new Chart(statusRef.current, {
            type: "doughnut",
            data: {
              labels: statusLabels,
              datasets: [
                {
                  label: "Order Status",
                  data: statusCounts,
                  backgroundColor: ["#f44336", "#2196f3", "#4caf50", "#ff9800", "#333", "#ccc"],
                },
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: "top",
                },
                tooltip: {
                  callbacks: {
                    label: (tooltipItem) => {
                      const index = tooltipItem.dataIndex;
                      return `${statusLabels[index]}: ${statusCounts[index]} orders`;
                    },
                  },
                },
              },
            },
          });
        }
      } catch (error) {
        console.error("Error Fetching Order Status Data:", error);
      }
    };

    fetchOrderStatus();
  }, []);


  // Order Summary

  useEffect(() => {
    const currentMonth = new Date().getMonth + 1;
    const currentYear = new Date().getFullYear();
    fetchOrderSummary(currentMonth, currentYear);
  }, []);



  const fetchOrderSummary = async (month, year) => {
    try {
      const response = await fetch(`https://api.vivekflowers.com/api/order-summary-report?month=${month}&year=${year}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setOrderSummary(data || null);
    }
    catch (err) {
      console.error('Error Fetching Order Summary', err);
      setOrderSummary(null);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    fetchOrderSummary(month, year);
  }
  const formatCurrency = (value) => {
    return value && !isNaN(value) ? value.toFixed(2) : "0.00";
  };

  return (

    <div class="container-scroller">

      <Header />

      <div class="container-fluid page-body-wrapper">

        <Sidebar />

        <div class="main-panel">

          <div class="content-wrapper">

            <div class="row">

              <div class="col-md-12 grid-margin">

                <div class="row">

                  <div class="col-12 col-xl-8 mb-4 mb-xl-0">

                    <h3 class="font-weight-bold">Welcome {userName} </h3>

                    <h6 class="font-weight-normal mb-0">All systems are running smoothly! 
                    {reportData.todays_orders > 0 && ' You have '}
                      {reportData.todays_orders > 0 && 
                       <span class="text-primary">{reportData.todays_orders} New orders today!</span>
                      }
                    </h6>

                  </div>
                </div>
              </div>
            </div>

            {/* end of the Headers */}


            {/* Dashboard Image */}


            <div class="row">
              <div class="col-md-6 grid-margin stretch-card">
                <div class="card tale-bg">
                  <div class="card-people mt-auto">
                    <img src="assets/images/dashboard/people.svg" alt="people" />
                    <div class="weather-info">
                      <div class="d-flex">
                        <div>
                          <h2 class="mb-0 font-weight-normal"><i class={`${formattedHours > 7 && amPm === 'PM' ? 'icon-moon' : 'icon-sun'} me-2`}></i>{formattedHours}:{formattedMinutes}<sup>{formattedSeconds}</sup></h2>
                        </div>
                        <div class="ms-2">
                          <h4 class="location font-weight-normal">{amPm}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* end of the image */}

              <div class="col-md-6 grid-margin transparent">
                <div class="row">
                  <div class="col-md-6 mb-4 stretch-card transparent">
                    <div class="card card-tale">
                      <div className="card-body">
                        <p className="mb-4">Total Sales</p>
                        <p className="mb-2" style={{ fontSize: '25px' }}>
                          ${Number(reportData.total_sales).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-4 stretch-card transparent">
                    <div class="card card-dark-blue">
                      <div class="card-body">
                        <p class="mb-4">Total Orders</p>
                        <p class="fs-30 mb-2">{Number(reportData.total_orders).toFixed(0)}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-4 mb-lg-0 stretch-card transparent">
                    <div class="card card-light-blue">
                      <div class="card-body">
                        <p class="mb-4">Monthly Sales</p>
                        <p class="fs-30 mb-2">${Number(reportData.monthly_sales).toFixed(2)}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 stretch-card transparent">
                    <div class="card card-light-danger">
                      <div class="card-body">
                        <p class="mb-4">Monthly Orders</p>
                        <p class="fs-30 mb-2">{Number(reportData.monthly_orders).toFixed(0)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              {/* Sales Report */}
              <div class="col-md-6 grid-margin stretch-card">

                <div class="card">

                  <div class="card-body">


                    <div class="d-flex justify-content-between">

                      <p class="card-title">Sales Report</p>

                      <div class="justify-content-end d-flex">
                      </div>

                      {/* <a href="#" class="text-info">View all</a> */}

                    </div>

                    <p class="font-weight-500">The total number of sessions within the date range. It is the period time a user is actively engaged with your website, page or app, etc</p>

                    <div id="sales-chart-legend" class="chartjs-legend mt-4 mb-2"></div>

                    {salesChartData ? (
                      <Bar
                        data={salesChartData}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              display: true,
                              position: "top",
                              labels: {
                                color: "#2c3e50",
                                font: {
                                  size: 14,
                                },
                              },
                            },
                            tooltip: {
                              backgroundColor: "#34495e",
                              titleColor: "#ecf0f1",
                              bodyColor: "#ecf0f1",
                              borderWidth: 1,
                              borderColor: "#ecf0f1",
                            },
                          },
                          scales: {
                            x: {
                              grid: {
                                display: false,
                              },
                              ticks: {
                                color: "#34495e",
                                font: {
                                  size: 12,
                                },
                              },
                            },
                            y: {
                              grid: {
                                color: "#ecf0f1",
                                borderDash: [5, 5],
                              },
                              ticks: {
                                color: "#34495e",
                                font: {
                                  size: 12,
                                },
                              },
                            },
                          },
                        }}
                      />
                    ) : (
                      <p>Loading...</p>
                    )}


                  </div>

                </div>

              </div>

              {/* end of the Sales Report  */}

              {/* Store Pickup */}
              <div class="col-md-6 grid-margin stretch-card">

                <div class="card">

                  <div class="card-body">

                    <div class="d-flex justify-content-between">

                      <p class="card-title">Store Pickup</p>
                    </div>

                    <p class="font-weight-500">The total number of sessions within the date range. It is the period time a user is actively engaged with your website, page or app, etc</p>

                    <div id="sales-chart-legend" class="chartjs-legend mt-4 mb-2"></div>

                    <div class="col-md-6 mt-3">

                      <div class="daoughnutchart-wrapper" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', margin: '0' }}>

                        <canvas ref={chartRef} height='500px' width='500px'></canvas>
                      </div>
                    </div>

                  </div>

                </div>

              </div>

              {/* End of the Store Pickup */}

              {/* Order Status */}

              <div class="col-md-6 grid-margin stretch-card">

                <div class="card">

                  <div class="card-body">

                    <div class="d-flex justify-content-between">

                      <p class="card-title">Order Status</p>

                    </div>

                    <p class="font-weight-500">The total number of sessions within the date range. It is the period time a user is actively engaged with your website, page or app, etc</p>

                    <div id="sales-chart-legend" class="chartjs-legend mt-4 mb-2"></div>

                    <div class="col-md-6 mt-3">

                      <div class="daoughnutchart-wrapper" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', margin: '0' }}>

                        <canvas ref={statusRef} height='600px' width='500px'></canvas>
                      </div>
                    </div>

                  </div>

                </div>

              </div>

              {/* Order Status end */}

              {/* Order Details */}
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="card-title mb-0">Order Details</p>

                      {/* DatePicker */}
                      <div style={{marginLeft:'5px'}}>
                        <DatePicker
                          selected={selectedDate} onChange={handleDateChange} dateFormat="MMMM yyyy"
                          showMonthYearPicker
                          showFullMonthYearPicker
                          disabledKeyboardNavigation
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          yearDropdownItemNumber={15}
                          minDate={new Date("01/01/2010")}
                          // maxDate={new Date()}

                        />
                      </div>


                    </div>

                    <p className="font-weight-500">
                      The total number of sessions within the date range. It is the period time a user is actively engaged with your website, page or app, etc
                    </p>
                    {orderSummary ? (
                      <div className="d-flex flex-wrap mb-5">
                        <div className="me-5 mt-3">
                          <p className="text-muted">Order value</p>
                          <h3 className="text-primary fs-30 font-weight-medium">${formatCurrency(orderSummary.totalSales)}</h3>
                        </div>
                        <div className="me-5 mt-3">
                          <p className="text-muted">Orders</p>
                          <h3 className="text-primary fs-30 font-weight-medium">{orderSummary.totalOrders}</h3>
                        </div>
                        <div className="me-5 mt-3">
                          <p className="text-muted">Registered Users</p>
                          <h3 className="text-primary fs-30 font-weight-medium">{orderSummary.uniqueCustomerPercentage}<samll>%</samll></h3>
                        </div>
                      </div>
                    ) : (
                      <div className="text-center mt-5">
                        <p className="text-muted">No Data Available</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* end of the Order Details */}

            </div>
          </div>

          <Footer />

        </div>
      </div>
    </div >


  );
};

export default Dashboard;