import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const EditProduct = () => {
    
    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);

    const location = useLocation();
    const products = location.state?.products;
    const [productData, setProductData] = useState(products);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [imagePreview, setImagePreview] = useState(products?.image);
    const [showForm, setShowForm] = useState(false);
    // const [variations, setVariations] = useState([
    //     {
    //         product_id: "",
    //         variation_name: "",
    //         variation_id: "",
    //         variation_amount: "",
    //         weight: "",
    //         withIceWeight: ""
    //     }
    // ]);
    const [variation, setVariation] = useState(
        {
            product_id: "",
            SKU:"",
            variation_name: "",
            variation_amount: "",
            weight: "",
            withIceWeight: ""
        }
    );


    console.log("Products", productData);


    // handle Input Changes

    const handleAddVariationChange = (e) => {
        const { name, value } = e.target;
        setVariation((prevVariation) => ({
            ...prevVariation,
            [name]: value
        }));
    };
    

    // const handleAddVariationChange = (index) => (e) => {
    //     const { name, value } = e.target;
    //     setVariations((prevVariations) =>
    //         prevVariations.map((variation, i) =>
    //             i === index ? { ...variation, [name]: value } : variation
    //         )
    //     );
    // };
    
    
    const addNewVariation = () => {
        setShowForm(true)
        
        
    };

    // const deleteVariation = (index) => {
    //     setVariation((prevVariations) => prevVariations.filter((_, i) => i !== index));
    // }

    const deleteVariation = async (variationId) => {
        try {
          const response = await axios.patch(`https://api.vivekflowers.com/api/variations/${variationId}/remove`, {
            remove: 1, 
          });
          if (response.status === 200) {
            alert('Variation removed successfully');
            
          }
        } catch (error) {
          console.error('Error removing variation:', error);
          alert('Failed to remove variation');
        }
      };
    const handleAddVariationSubmit = async (e) => {
        e.preventDefault();
        
    
        try {
            const response = await axios.post('https://api.vivekflowers.com/api/variations', variation, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
    
            if (response.status === 201) {
                
                setVariation({
                    product_id: '',
                    SKU: '',
                    variation_name: '',
                    variation_amount: '',
                    weight: '',
                    withIceWeight: ''
                });
    
                toast.success("Product Variation Added successfully!", {
                    position: "top-right",
                });
    
                setTimeout(() => {
                    navigate("/products");
                }, 5000);
            }
        } catch (error) {
            console.error('Error adding variation:', error);
            alert('Error adding variation.');
        }
    };
    


    useEffect(() => {
        axios.get("https://api.vivekflowers.com/api/category")
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.error("Error fetching categories:", error);
            });

        axios.get("https://api.vivekflowers.com/api/subcategory")
            .then((response) => {
                setSubcategories(response.data);
            })
            .catch((error) => {
                console.error("Error fetching subcategories:", error);
            });
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProductData((prevState) => ({ ...prevState, [name]: value }));
    };


    // variation for edit
    const handleVariationChange = (index) => (e) => {
        const { name, value } = e.target;
        const newVariations = [...productData.variations];
        newVariations[index] = { ...newVariations[index], [name]: value };
        setProductData({ ...productData, variations: newVariations });

    };
    

    const handleVariationSubmit = async (variation_id) => {
        const variationToSubmit = productData.variations[variation_id];
        console.log("Variationtosubmit:", variationToSubmit);



        try {
            const response = await fetch(`https://api.vivekflowers.com/api/variations/${variationToSubmit.variation_id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(variationToSubmit),
            });

            if (!response.ok) {
                throw new Error("Error updating variation");
            }

            const result = await response.json();
            console.log("Variation updated successfully:", result);
            alert("Variation updated successfully!");

        } catch (error) {
            console.error("Error submitting variation:", error);
            alert("Failed to update variation. Please try again.");
        }
    };



    const handleFileChange = (e) => {
        const file = e.target.files[0];
        console.log("Selected File:", file);
        if (file) {
            setImagePreview(URL.createObjectURL(file));
            setProductData((prevState) => ({ ...prevState, image_url: file }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();



        const { id, image_url, ...updatedProduct } = productData;

        console.log("Updated Product Data:", updatedProduct);

        const formData = new FormData();
        console.log('FormData:', formData);


        Object.keys(updatedProduct).forEach((key) => {
            formData.append(key, updatedProduct[key]);
        });

        if (image_url instanceof File) {
            formData.append("image", image_url);
        } else if (typeof image_url === "string") {
            formData.append("existingImage", image_url);
        }

        console.log("FormData image:", formData.get("image"));

        axios
            .put(`https://api.vivekflowers.com/api/editproduct/${id}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                toast.success("Products Updated successfully!", {
                    position: "top-right",
                });
                setTimeout(() => {
                    navigate("/products");
                }, 5000);
            })
            .catch((error) => {
                console.error("Error updating product:", error);
                toast.error("Failed to update product. Please try again.");

            });
    };




    return (

        <div className="container-scroller">

            <div className="container-fluid page-body-wrapper">

                <Header />

                <Sidebar />

                <div className="main-panel">

                    <div className="content-wrapper">

                        <div className="row">

                            <div className="col-lg-12 grid-margin stretch-card">

                                <div className="card">

                                    <div className="card-body">

                                        <div className="col-12 grid-margin stretch-card">

                                            <div className="card">

                                                <div className="card-body">

                                                    <h4 className="card-title">Edit Product</h4>

                                                    <form className="forms-sample" onSubmit={handleSubmit}>

                                                        <div className="row">

                                                            {/* SKU ID */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="SKU">SKU</label>
                                                                    <input type="text" className="form-control" name="SKU" value={productData.SKU || ""} onChange={handleChange} required />
                                                                </div>
                                                            </div>

                                                            {/* Product Name */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="productName">Product Name</label>
                                                                    <input type="text" className="form-control" name="productName" value={productData.productName || ""} onChange={handleChange} placeholder="Enter Product Name..." required />
                                                                </div>
                                                            </div>

                                                            {/* Product ID */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="p_id">Product ID</label>
                                                                    <input type="text" className="form-control" name="p_id" value={productData.p_id} onChange={handleChange} placeholder="Enter Product ID..." required />
                                                                </div>
                                                            </div>

                                                            {/* Amount */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="amount">Amount</label>
                                                                    <input type="number" className="form-control" name="amount" value={productData.amount || ""} onChange={handleChange} placeholder="Enter Amount..." required />
                                                                </div>
                                                            </div>

                                                            {/* Description */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="description">Description</label>
                                                                    <input type="text" className="form-control" name="description" value={productData.description || ""} onChange={handleChange} placeholder="Enter Description..." />
                                                                </div>
                                                            </div>

                                                            {/* Short Description */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="shortdescription">Short Description</label>
                                                                    <input type="text" className="form-control" name="shortdescription" value={productData.shortdescription || ""} onChange={handleChange} placeholder="Enter Short Description..." required />
                                                                </div>
                                                            </div>

                                                            {/* Weight */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="weight">Weight (in pounds)</label>
                                                                    <input type="number" className="form-control" name="weight" value={productData.weight || ""} onChange={handleChange} placeholder="Enter Weight in pounds..." required />
                                                                </div>
                                                            </div>

                                                            {/* Ice Weight */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="weightWithIce">Ice Weight</label>
                                                                    <input type="number" className="form-control" name="weightWithIce" value={productData.weightWithIce || ""} onChange={handleChange} placeholder="Enter Ice Weight..." required />
                                                                </div>
                                                            </div>

                                                            {/* Category ID */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="category_id">Category</label>
                                                                    <select className="form-control" name="category_id" value={productData.category_id} onChange={handleChange} >
                                                                        <option value="" disabled>Select the Category</option>
                                                                        {categories.map((category) => (
                                                                            <option key={category.id} value={category.id}>
                                                                                {category.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            {/* Subcategory ID */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="subcategory_id">Sub Category</label>
                                                                    <select className="form-control" name="subcategory_id" value={productData.subcategory_id} onChange={handleChange} >
                                                                        <option value="" disabled>Select the Subcategory</option>
                                                                        {subcategories.map((subcategory) => (
                                                                            <option key={subcategory.id} value={subcategory.id}>
                                                                                {subcategory.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            {/* Image Upload */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="image">Upload Image</label>
                                                                    <input type="file" name="image" className="form-control" onChange={handleFileChange} />
                                                                </div>
                                                                {imagePreview && (
                                                                    <div>
                                                                        <img
                                                                            src={imagePreview}
                                                                            alt="Image Preview"
                                                                            style={{ maxWidth: "40%", height: "auto" }}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>

                                                        </div>

                                                        <button type="submit" className="btn btn-primary btn-icon-text mr-2">
                                                            <i className="ti-file btn-icon-prepend"></i> Submit
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-icon-text"
                                                            style={{ marginLeft: '10px', height: '45px', width: '120px' }}>Cancel
                                                        </button>
                                                    </form>
                                                    <ToastContainer />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            {/* Product with Variation */}

                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-12 grid-margin stretch-card">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h4 className="card-title">Edit Product With Variation</h4>
                                                    <form className="forms-sample" onSubmit={handleSubmit}>
                                                        <div className="row">

                                                            {productData.variations && productData.variations.length > 0 && (
                                                                productData.variations.map((variation, index) => (
                                                                    <div className="col-12" key={index}>
                                                                        <h5 className="my-4">Variation {index + 1}</h5>
                                                                        <div className="row">
                                                                            {/* Product ID */}
                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor={`product_id${index}`}>Product ID</label>
                                                                                    <input type="text" className="form-control" name="product_id" value={variation.product_id || ""} onChange={handleVariationChange(index)} placeholder="Enter Product ID..." required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor={`variation_name_${index}`}>Variation Name</label>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="variation_name"
                                                                                        value={variation.variation_name || ""}
                                                                                        onChange={handleVariationChange(index)}
                                                                                        placeholder="Enter Variation Name..."
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="variation_id">Variation ID</label>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="variation_id"
                                                                                        value={variation.variation_id || ""}
                                                                                        onChange={handleVariationChange(index)}
                                                                                        placeholder="Enter Variation ID..."
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="variation_amount">Variation Amount</label>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control"
                                                                                        name="variation_amount"
                                                                                        value={variation.variation_amount || ""}
                                                                                        onChange={handleVariationChange(index)}
                                                                                        placeholder="Enter Variation Amount..."
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor={`weight${index}`}>Weight (in pounds)</label>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control"
                                                                                        name="weight"
                                                                                        value={variation.weight || ""}
                                                                                        onChange={handleVariationChange(index)}
                                                                                        placeholder="Enter Weight in pounds..."
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor={`withIceWeight${index}`}>Ice Weight</label>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control"
                                                                                        name="withIceWeight"
                                                                                        value={variation.withIceWeight || ""}
                                                                                        onChange={handleVariationChange(index)}
                                                                                        placeholder="Enter Ice Weight..."
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary btn-icon-text mr-2"
                                                                            onClick={() => handleVariationSubmit(index)}>
                                                                            <i className="ti-file btn-icon-prepend"></i> Submit</button>
                                                                        <button type="button" className="btn btn-primary btn-icon-text" style={{ marginLeft: '10px', height: '45px', width: '120px' }}>
                                                                            Cancel
                                                                        </button>
                                                                        <button
    type="button"
    className="btn btn-danger btn-icon-text"
    onClick={() => deleteVariation(variation.variation_id)}
  >
    <i className="ti-trash btn-icon-prepend"></i> Delete
  </button>
                                                                    </div>
                                                                ))
                                                            )}
                                                        </div>
                                                    </form>
                                                    <ToastContainer />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Add Variation */}
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">Add Variation</h4>

                                        <button
                                            className="btn btn-success btn-icon-text text-white"
                                            onClick={addNewVariation}
                                        >
                                            <i className="ti-plus btn-icon-prepend"></i> Add New Variation
                                        </button>

                                        {showForm && (
                                            <form className="forms-sample mt-4" onSubmit={handleAddVariationSubmit}>
                                                <div className="row">
                                                    {/* {variations.map((variation, index) => ( */}
                                                        <div className="col-12" >
                                                            <h5 className="my-4">Variation</h5>
                                                            <div className="row">
                                                                {/* Product ID */}
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor={`product_id`}>Product ID</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="product_id"
                                                                            value={variation.product_id}
                                                                            onChange={handleAddVariationChange}
                                                                            placeholder="Enter Product ID..."
                                                                        />
                                                                    </div>
                                                                </div>

                                                                {/* Variation Name */}
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor={`variation_name`}>
                                                                            Variation Name
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="variation_name"
                                                                            value={variation.variation_name}
                                                                            onChange={handleAddVariationChange}
                                                                            placeholder="Enter Variation Name..."
                                                                        />
                                                                    </div>
                                                                </div>

                                                                {/* Variation ID */}
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor={`SKU`}>SKU</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="SKU"
                                                                            value={variation.SKU}
                                                                            onChange={handleAddVariationChange}
                                                                            placeholder="Enter SKU ID..."
                                                                        />
                                                                    </div>
                                                                </div>

                                                                {/* Variation Amount */}
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor={`variation_amount`}>
                                                                            Variation Amount
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            name="variation_amount"
                                                                            value={variation.variation_amount}
                                                                            onChange={handleAddVariationChange}
                                                                            placeholder="Enter Variation Amount..."
                                                                        />
                                                                    </div>
                                                                </div>

                                                                {/* Weight */}
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor={`weight`}>
                                                                            Weight (in pounds)
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            name="weight"
                                                                            value={variation.weight}
                                                                            onChange={handleAddVariationChange}
                                                                            placeholder="Enter Weight in pounds..."
                                                                        />
                                                                    </div>
                                                                </div>

                                                                {/* Ice Weight */}
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor={`withIceWeight`}>Ice Weight</label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            name="withIceWeight"
                                                                            value={variation.withIceWeight}
                                                                            onChange={handleAddVariationChange}
                                                                            placeholder="Enter Ice Weight..."
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* Buttons */}
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary btn-icon-text mr-2" onClick={handleAddVariationSubmit} >
                                                                <i className="ti-file btn-icon-prepend"></i> Submit
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger btn-icon-text"
                                                                onClick={() => deleteVariation}
                                                            >
                                                                <i className="ti-trash btn-icon-prepend"></i> Delete
                                                            </button>
                                                        </div>
                                                    {/* ))} */}
                                                </div>

                                            </form>
                                        )}
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                    <Footer/>
                </div>
            </div >
        </div >

    );
};

export default EditProduct;
