import React, { useEffect, useState } from 'react';
import Header from '../../includes/navBar';
import Sidebar from '../../includes/sideBar';
import Footer from '../../includes/footer';
import axios from 'axios';
import { FormControl, MenuItem, Select } from '@mui/material';
import * as XLSX from 'xlsx';
import Loader from '../loader';

const Report = () => {

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0');
    const [loader, setLoader] = useState(true);
    const [getData, setGetData] = useState([]);
    const [totals, setTotals] = useState({ total: 0, shipping: 0, grandTotal: 0 });
    const [selectedMonth, setSelectedMonth] = useState(`${currentYear}-${currentMonth}`);
    const [ordercount, setOrderCount]=useState(0);
    const [filters, setFilters]=useState([]);
    const [filterDatas, setFilterData]=useState([]);

    useEffect(() => {
        fetchInvoiceData();
    }, [selectedMonth]);

    const handleMonthChange = (e) => {
        const date = e.target.value;
        setSelectedMonth(date);
    };

    const handleStatusChange = (e) => {
        const status = e.target.value;
        setFilters(prevFilters => {
            const newStatuses = prevFilters.includes(status)
                ? prevFilters.filter(s => s !== status)
                : [...prevFilters, status];
            return newStatuses;
        });
    };

    const handleStatusRemove = (status) => {
       
        setFilters(prevFilters => {
            const newStatuses = prevFilters.includes(status)
                ? prevFilters.filter(s => s !== status)
                : [...prevFilters, status];
            return newStatuses;
        });
    };

    const filterData = () => {

        if(filters && filters.length> 0){
            const filteredData = getData?.filter(item => filters.includes(item.status));
            setFilterData(filteredData);
            setOrderCount(filteredData.length || 0);
            const totalSum = filteredData.reduce((sum, order) => sum + parseFloat(order.total || 0), 0);
            const shippingSum = filteredData.reduce((sum, order) => sum + parseFloat(order.shipping || 0), 0);
            const grandTotalSum = filteredData.reduce((sum, order) => sum + parseFloat(order.grandTotal || 0), 0);

            setTotals({
                total: totalSum.toFixed(2),
                shipping: shippingSum.toFixed(2),
                grandTotal: grandTotalSum.toFixed(2),
            });
        }else{
            const filteredData = getData;
            setFilterData(filteredData);
            setOrderCount(filteredData.length || 0);
            const totalSum = filteredData.reduce((sum, order) => sum + parseFloat(order.total || 0), 0);
            const shippingSum = filteredData.reduce((sum, order) => sum + parseFloat(order.shipping || 0), 0);
            const grandTotalSum = filteredData.reduce((sum, order) => sum + parseFloat(order.grandTotal || 0), 0);

            setTotals({
                total: totalSum.toFixed(2),
                shipping: shippingSum.toFixed(2),
                grandTotal: grandTotalSum.toFixed(2),
            });
        }
    };

    useEffect(()=> {
        filterData();
    },[filters]);

    const fetchInvoiceData = async () => {
        setLoader(true);
        const month = selectedMonth.replace("-", "/");
        try {
            const response = await axios.get(`https://api.vivekflowers.com/api/order-report?month=${month}`);
            if (response.status === 200) {
                const orders = response.data.orders;
                setGetData(orders);
                setFilterData(orders);
                setOrderCount(response.data.orderCount);
                console.log(response.data);
                const totalSum = orders.reduce((sum, order) => sum + parseFloat(order.total || 0), 0);
                const shippingSum = orders.reduce((sum, order) => sum + parseFloat(order.shipping || 0), 0);
                const grandTotalSum = orders.reduce((sum, order) => sum + parseFloat(order.grandTotal || 0), 0);

                setTotals({
                    total: totalSum.toFixed(2),
                    shipping: shippingSum.toFixed(2),
                    grandTotal: grandTotalSum.toFixed(2),
                });
                setLoader(false);
            } else {
                setGetData([]);
                setTotals({ total: 0, shipping: 0, grandTotal: 0 });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setGetData([]);
            setTotals({ total: 0, shipping: 0, grandTotal: 0 });
        }
    };

    const downloadExcel = () => {
        const ws = XLSX.utils.table_to_sheet(document.getElementById('printableArea'));
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Data');
        XLSX.writeFile(wb, 'monthly_Report.xlsx');
    };

   

    return (
        <>
        {loader && <Loader />}
        <div className="container-scroller">
            <Header />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row mt-4">

                                            <div className="col-lg-6 mb-4" align="left">
                                                <h4 className="card-title">Orders {ordercount}</h4>
                                                {filters?.map(data=>
                                                    <button class="btn btn-warning mx-1 mt-1" onClick={()=> handleStatusRemove(data)}>{data} x</button>
                                                )}
                                            </div>

                                            <div className="col-lg-2 mb-4" align="right">
                                                
                                                    <input class="form-select" type="month" value={selectedMonth} onChange={handleMonthChange} />
                                                
                                            </div>

                                            <div className="col-lg-2 mb-4" align="right">
                                               
                                                    <select class="form-select"  onChange={handleStatusChange} >
                                                        <option value="" hidden>Add Filters</option>
                                                        <option value="Delivered">Delivered</option>
                                                        <option value="Refunded">Refunded</option>
                                                        <option value="Order Placed">Order Placed</option>
                                                        <option value="Cancelled">Cancelled</option>
                                                        <option value="Shipped">Shipped</option>
                                                        <option value="Delayed">Delayed</option>
                                                    </select>
                                              
                                            </div>

                                            <div className="col-lg-2 mb-4" align="right">
                                                <button onClick={downloadExcel} className="btn btn-primary">Download Excel</button>
                                            </div>

                                        </div>
                                       
                                        <div className="table-responsive mt-4" style={{maxHeight: '70vh'}}>
                                            <table className="table" id="printableArea">
                                                <thead>
                                                    <tr>
                                                        <th>Invoice No</th>
                                                        <th>Order Id</th>
                                                        <th>Paypal Id</th>
                                                        <th>Time</th>
                                                        <th>Order Date</th>
                                                        <th>Delivery Date</th>
                                                        <th>Order Type</th>
                                                        <th>Items</th>
                                                        <th>Sub Total</th>
                                                        <th>Shipping</th>
                                                        <th>Grand Total</th>
                                                        <th>Order Status</th>
                                                        <th>Customer Name</th>
                                                        <th>Email</th>
                                                        <th>Phone</th>
                                                        <th>Address</th>
                                                        <th>City</th>
                                                        <th>State</th>
                                                        <th>Country</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filterDatas.length > 0 ? filterDatas.map((data, index) => (
                                                        <tr key={index}>
                                                            <td>{data.invNo}</td>
                                                            <td>{data.orderId}</td>
                                                            <td>{data.payPalId}</td>
                                                            <td>{data.orderPlaced}</td>
                                                            <td>{data.date}</td>
                                                            <td>{data.deliveryDate}</td>
                                                            <td>{data.orderType}</td>
                                                            <td>{data.items}</td>
                                                            <td>${parseFloat(data.total).toFixed(2)}</td>
                                                            <td>${parseFloat(data.shipping).toFixed(2)}</td>
                                                            <td>${parseFloat(data.grandTotal).toFixed(2)}</td>
                                                            <td>{data.status}</td>
                                                            <td>{data.fName} {data.lName}</td>
                                                            <td>{data.email}</td>
                                                            <td>{data.phone}</td>
                                                            <td>{data.address1} {data.address2}</td>
                                                            <td>{data.city}</td>
                                                            <td>{data.state}</td>
                                                            <td>{data.country}</td>
                                                            
                                                        </tr>
                                                    )) : (
                                                        <tr>
                                                            <td colSpan="15" className="text-center">No records found</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                                {getData.length > 0 && (
                                                    <tfoot>
                                                        <tr>
                                                            <th colSpan="5" className="text-right font-weight-bold">Total </th>
                                                            <th>${totals.total}</th>
                                                            <th>${totals.shipping}</th>
                                                            <th>${totals.grandTotal}</th>
                                                            <th></th>
                                                        </tr>
                                                    </tfoot>
                                                )}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
      </div>
    </>
    );
};

export default Report;