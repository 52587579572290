import React, { useEffect, useState } from 'react';
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import axios from 'axios';
import { Pagination, Select, MenuItem, FormControl } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from './loader';

function formatNumber(number, length) {
    return String(number).padStart(length, '0');
}
const Orders = () => {

    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
        navigate('/');
        }
    }, [user]);

    const [orders, setOrders] = useState([]);
    const [search, setSearch] = useState('');
    const [rowsperpage, setRowsPerPage] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchField, setSearchField] = useState('id');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [orderType, setOrderType] = useState('both');
    const [loader, setLoader] = useState(true);

    const searchFields = [
        { label: 'Order ID', value: 'id' },
        { label: 'Customer First Name', value: 'fName' },
        { label: 'Customer Last Name', value: 'lName' },
        { label: 'Email', value: 'email' },
        { label: 'Delivery Date and Order Type', value: 'orderType' }
        
    ];

  

    // const filteredProducts = orders.filter((product) => {
    //     const Searchorders = product[searchField]?.toString().toLowerCase().includes(search.toLowerCase());
    //     const SearchOrderType = orderType === 'both' ||
    //         (orderType === 'pickup' && product.orderType === 'pickup') ||
    //         (orderType === 'delivery' && product.orderType === 'delivery');
    //     return Searchorders && SearchOrderType;
    // });

    const fetchOrders = (page = 1) => {

        const newFromDate = fromDate ? new Date(fromDate) : null;
        const fromYear = newFromDate?.getFullYear();
        const fromMonth = String(newFromDate?.getMonth() + 1).padStart(2, '0');
        const fromDay = String(newFromDate?.getDate()).padStart(2, '0');
        const formattedFromDate = newFromDate ? `${fromYear}-${fromMonth}-${fromDay}` : '';

        const newToDate =  toDate ? new Date(toDate) : null;
        const toYear = newToDate?.getFullYear();
        const toMonth = String(newToDate?.getMonth() + 1).padStart(2, '0');
        const toDay = String(newToDate?.getDate()).padStart(2, '0');
        const formattedToDate = newToDate ? `${toYear}-${toMonth}-${toDay}` : '';

        //const formattedFromDate = newFromDate ? newFromDate.toISOString().split('T')[0] : '';
        //const formattedToDate = newToDate ? newToDate.toISOString().split('T')[0] : '';

        const params = {
            page: page,
            pageSize: rowsperpage,
            orderType: orderType !== 'both' ? orderType : undefined,
            fromDate: formattedFromDate,
            toDate: formattedToDate,
        };

        if (search && searchField) {
            params[searchField] = search;
        }

        axios.get(`https://api.vivekflowers.com/api/all-orders`, { params })
            .then((response) => {
                setOrders(response?.data || []);
                setTotalPages(response?.data?.pagination?.total_pages || 1);
                setLoader(false);
            })
            .catch((error) => { 
                setOrders([]);
                setTotalPages(1);
                console.error('Error fetching Orders:', error)
            });
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
        fetchOrders(page); 
    };

    const handleViewClick = (order) => {
        navigate(`/orderdetails?=${order.orderId}`, { state: { order } });
    };


    useEffect(() => {
        fetchOrders(currentPage);
    }, [currentPage, rowsperpage, fromDate, toDate, orderType, searchField, search]);

    const alertTrack = () => {
        alert('Change order status to shipped to get tracking details!');
    };
    return (
        <>
        {loader && <Loader />}
        <div className="container-scroller">
            <Header />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="card-title">Orders {orders?.pagination?.total_orders || 0}</h4>
                                            <div className="d-flex">
                                                <FormControl variant="outlined" size="p" style={{ marginRight: '10px' }}>
                                                    <Select value={searchField} onChange={(e) => setSearchField(e.target.value)}>
                                                        {searchFields.map((field) => (
                                                            <MenuItem key={field.value} value={field.value}>{field.label}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                                {['date'].includes(searchField) && (
                                                    <div className="d-flex">
                                                        <DatePicker selected={fromDate} onChange={(date) => setFromDate(date)} dateFormat="yyyy-MM-dd" className="form-control"  placeholderText="From Date"/>
                                                        <DatePicker selected={toDate} onChange={(date) => setToDate(date)} dateFormat="yyyy-MM-dd" className="form-control" placeholderText="To Date" />
                                                    </div>
                                                )}

                                                {['orderType'].includes(searchField) && (
                                                    <div className="d-flex">
                                                        <DatePicker selected={fromDate} onChange={(date) => setFromDate(date)} dateFormat="yyyy-MM-dd" className="form-control custom-date-picker date-gap" placeholderText="From Date" />
                                                        <DatePicker selected={toDate} onChange={(date) => setToDate(date)} dateFormat="yyyy-MM-dd" className="form-control custom-date-picker date-gap" placeholderText="To Date" />
                                                        <select className="form-control date-type-selector" value={orderType} onChange={(e) => setOrderType(e.target.value)} >
                                                            <option value="both">Both</option>
                                                            <option value="pickup">Pickup</option>
                                                            <option value="delivery">Delivery</option>
                                                        </select>
                                                    </div>
                                                )}
                                                {!['date', 'orderType'].includes(searchField) && (
                                                <input type="text" className="form-control w-50 responsive-search-bar" placeholder={`Search by ${searchField}`} value={search} onChange={(e) => setSearch(e.target.value)} style={{ maxWidth: "300px", height: '40px' }} />
                                                )}
                                            </div>
                                        </div>
                                        <div className="table-responsive mt-4">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Order ID</th>
                                                        {/* <th>Order Value</th> */}
                                                        <th>Order Date</th>
                                                        <th>Customer</th>
                                                        <th>Order Type</th>
                                                        {/* <th>Items</th> */}
                                                        <th>Status</th>
                                                        <th>Delivery/Pickup Date</th>
                                                        <th>Amount</th>
                                                        <th>Shipping</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orders?.orders?.length > 0 ? (
                                                        orders?.orders?.map((order, index) => (
                                                            <tr key={index}>
                                                                
                                                                <td>{formatNumber(order.invNo, 7)}</td>
                                                                {/* <td>{order.orderId}</td> */}
                                                                <td>{order.date}</td>
                                                                <td>{order.fName} {order.lName}</td>
                                                                <td>
                                                                    {order.orderType === 'delivery' ? (
                                                                        <p className="text-primary">Delivery</p>
                                                                    ) : (
                                                                        <p className="text-success">Pickup</p>
                                                                    )}
                                                                </td>
                                                                {/* <td>{order.items}</td> */}
                                                                <td>
                                                                    {order.status === 'Delivered' ? (
                                                                        <p className="text-success">{order.status}</p>
                                                                    ) : order.status === 'Cancelled' ? (
                                                                        <p className="text-danger">{order.status}</p>
                                                                    ) : order.status === 'Refunded' ? (
                                                                        <p className="text-danger">{order.status}</p>
                                                                    ) : order.status === 'Shipped' ? (
                                                                        <p className="text-primary">{order.status}</p>
                                                                    ) : order.status === 'Delayed' ? (
                                                                        <p className="text-warning">{order.status}</p>
                                                                    ) : order.status === 'Order Placed' ? (
                                                                        <p className="text-info">{order.status}</p>
                                                                    ) : (
                                                                        <p className="text-danger">Unable to fetch Payment Status</p>
                                                                    )}
                                                                </td>
                                                                <td>{order.deliveryDate}</td>
                                                                <td>${Number(order.total).toFixed(2)}</td>
                                                                <td>${Number(order.shipping).toFixed(2)}</td>
                                                                {/* <td>${Number(order.grandTotal).toFixed(2)}</td> */}
                                                                <td>
                                                                    <button
                                                                        className="btn btn-sm btn-primary"
                                                                        onClick={() => handleViewClick(order)}
                                                                    >
                                                                        View
                                                                    </button>
                                                                    {order.tracking ? 
                                                                        <a className="btn btn-sm btn-success" style={{marginLeft: '5px'}} target="_blank" href={`https://www.fedex.com/fedextrack/?trknbr=${order.tracking}`} >Track</a>
                                                                    :
                                                                        <button className="btn btn-sm btn-secondary" style={{marginLeft: '5px'}} onClick={alertTrack} >Track</button>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="10" className="text-center">No Data Available</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="d-flex flex-wrap justify-content-between align-items-center p-2">
                                            <FormControl variant="outlined" size="p" style={{ marginRight: '10px', marginBottom: '10px', minWidth: '80px' }}>
                                                <Select
                                                    value={rowsperpage}
                                                    onChange={(e) => {
                                                        setRowsPerPage(e.target.value);
                                                        setCurrentPage(1);
                                                    }}
                                                >
                                                    <MenuItem value={5}>5</MenuItem>
                                                    <MenuItem value={10}>10</MenuItem>
                                                    <MenuItem value={15}>15</MenuItem>
                                                </Select>
                                            </FormControl>

                                            <Pagination
                                                count={totalPages}
                                                page={currentPage}
                                                onChange={handlePageChange}
                                                variant="outlined"
                                                color="primary"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
        </>
    );
};

export default Orders;