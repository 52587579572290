import React, { useEffect, useState } from 'react';
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const AddPost = () => {
    
    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);

    const [postData, setPostData] = useState({
        image: "",

    });
    console.log(postData);



    const handleFileChange = (e) => {
        setPostData({ ...postData, image: e.target.files[0] });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        Object.entries(postData).forEach(([key, value]) => {
            formData.append(key, value);
        });


        try {
            const response = await axios.post("https://api.vivekflowers.com/api/image", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            alert("Post added successfully!");
            navigate(`/gallery`);
            console.log(response.data);

            setPostData({
                image: "",

            });
        } catch (error) {
            console.error("Error adding product:", error);
            alert("Failed to add product. Please try again.");
        }
    };


    const handleCancel = () => {
        setPostData({
            image: "",

        });
    }

    return (
        <div className="container-scroller">

            <div className="container-fluid page-body-wrapper">

                <Header />

                <Sidebar />

                <div className="main-panel">

                    <div className="content-wrapper">

                        <div className="row">

                            <div className="col-lg-12 grid-margin stretch-card">

                                <div className="card">

                                    <div className="card-body">

                                        <div className="col-12 grid-margin stretch-card">

                                            <div className="card">

                                                <div className="card-body">

                                                    <h4 className="card-title">Add Post</h4>

                                                    <form className="forms-sample" onSubmit={handleSubmit}>

                                                        <div className="row">

                                                            {/* Image Upload */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="image">Upload Image</label>
                                                                    <input
                                                                        type="file"
                                                                        name="image"
                                                                        className="form-control"
                                                                        onChange={handleFileChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <button type="submit" className="btn btn-primary btn-icon-text mr-2">
                                                            <i className="ti-file btn-icon-prepend"></i> Submit
                                                        </button>
                                                        <button onClick={handleCancel}
                                                            type="button"
                                                            className="btn btn-primary btn-icon-text"
                                                            style={{ marginLeft: '10px', height: '45px', width: '120px' }}>
                                                            <i className="ti-close btn-icon-prepend"></i>

                                                            Cancel
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddPost;
