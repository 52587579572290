import React, { useEffect, useState } from 'react';
import Header from '../../includes/navBar';
import Sidebar from '../../includes/sideBar';
import Footer from '../../includes/footer';
import axios from 'axios';
import { Pagination, Select, MenuItem, FormControl, FormControlLabel, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';



const Banner = () => {

    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);

    const [post, setPost] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
  

    const fetchPost = () => {
        axios
            .get(`https://api.vivekflowers.com/api/get-banner`)
            .then((response) => {
                setPost(response.data);
            })
            .catch((error) => console.error('Error fetching post:', error));
    };

    const handleRemove = (id, currentRemoveStatus) => {
        axios
            .put(`https://api.vivekflowers.com/api/banner/remove/${id}`, {
                remove: currentRemoveStatus === 1 ? 0 : 1,
            })
            .then((response) => {
                alert(response.data.message);
                fetchPost();
            })
            .catch((error) => console.error('Error updating remove status:', error));
    };

    const handlePageChange = (e, page) => {
        setCurrentPage(page);
    };

    const handleEdits = (post) => {
        navigate("/edit-banner", { state: { posts: post } });
    };

    const handleToggleEnabled = (id, enabled) => {
        const enableValue = enabled ===1 ? 0 : 1;
        console.log("enableValue:", enableValue);
    
        axios
            .put(
                `https://api.vivekflowers.com/api/banner/enable/${id}`,
                {enable:enableValue },
                { headers: { "Content-Type": "application/json" } } // Ensure correct format
            )
            .then((response) => {
                console.log("Enable status updated successfully", response);
                alert(response.data.message || "Enable status updated successfully");
    
                // Update state only after a successful API response
                setPost((prevGallery) =>
                    prevGallery.map((product) =>
                        product.id === id ? { ...product, enable: enableValue } : product
                    )
                );
            })
            .catch((error) => {
                console.error("Error updating enable status:", error);
                alert("Failed to update enable status. Please try again.");
            });
    };
    

    useEffect(() => {
        fetchPost();
    }, []);




    return (
        <>

            <div className="container-scroller">

                <Header />

                <div className="container-fluid page-body-wrapper">

                    <Sidebar />

                    <div className="main-panel">

                        {/* Post */}

                        <div className="content-wrapper">

                            <div className="row">

                                <div className="col-lg-12 grid-margin stretch-card">

                                    <div className="card">

                                        <div className="card-body">

                                            <div className="d-flex justify-content-between align-items-center">
                                                <h4 className="card-title">Banner</h4>
                                                <a href='/add-banner'><button
                                                    className="btn btn-primary"
                                                    style={{ padding: '10px 20px', borderRadius: '4px' }}> Add banner</button></a>
                                            </div>


                                            <div className="table-responsive mt-4">

                                                <table className="table">

                                                    <thead>

                                                        <tr>
                                                            <th>Images</th>
                                                            <th>Enabled</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {post.map((posts, index) => (
                                                            <tr key={index}>
                                                                <td className="py-1">
                                                                    <img
                                                                        src={`https://api.vivekflowers.com/uploads/${posts.image}`}
                                                                        alt={posts.name}

                                                                        style={{ width: '250px', height: 'auto', marginRight: '10px', borderRadius: '0' }}
                                                                    />
                                                                    {posts.name}
                                                                </td>

                                                                <td>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch checked={posts.enable ===1} onChange={(e) => handleToggleEnabled(posts.id, posts.enable)} color="primary" />}
                                                                        label={posts.enable === 1 ? 'Yes' : 'No'} />
                                                                </td>

                                                                <td>
                                                                    <label className="badge badge-danger pointer" style={{ marginRight: "10px" }} onClick={() => handleRemove(posts.id, posts.remove)} >
                                                                        <i className="mdi mdi-delete" style={{ fontSize: "18px" }}></i>
                                                                    </label>
                                                                    <label className="badge badge-secondary pointer">

                                                                        <i a className="mdi mdi-storefront-edit" onClick={() => handleEdits(posts)} style={{ fontSize: "18px" }}></i>

                                                                    </label>

                                                                </td>


                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>


                                            </div>
                                            <div className="d-flex flex-wrap justify-content-between align-items-center p-2">

                                                <FormControl variant="outlined" size="small" style={{ marginRight: '10px', marginBottom: '10px', minWidth: '80px' }} >
                                                    <Select labelId="rows-per-page-label" value={rowsPerPage} onChange={(e) => { setRowsPerPage(e.target.value); setCurrentPage(1); }}>
                                                        <MenuItem value={5}>5</MenuItem>
                                                        <MenuItem value={10}>10</MenuItem>
                                                        <MenuItem value={15}>15</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} variant="outlined" color="primary" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Footer />
                    </div>
                </div>
            </div>


        </>
    )
}
export default Banner;
