import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Loader from './loader';

const Login = () => {
  const [email, setEmail] = useState('');
  console.log(email);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();

    if (!email || !password) {
      setError('Both fields are required');
      return;
    }
    try {
      const response = await fetch("https://api.vivekflowers.com/api/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      //console.log(data);
      if (response.ok) {
        sessionStorage.setItem('VVKADuser', data.user.user_id);
        sessionStorage.setItem('VVKADuserName', data.user.name);
        navigate('/dashboard');
        setLoader(false);
      } else {
        setError(data.error || 'Something went wrong');
        setLoader(false);
      }
    } catch (err) {
      setError('Unable to connect to the server');
    }
  };
  return (
    <>
{loader && <Loader />}
      <div class="container-scroller">

        <div class="container-fluid page-body-wrapper full-page-wrapper">

          <div class="content-wrapper d-flex align-items-center auth px-0">

            <div class="row w-100 mx-0">

              <div class="col-lg-4 mx-auto">

                <div class="auth-form-light text-left py-5 px-4 px-sm-5">

                  <div class="brand-logo">

                    <img src="../../assets/images/viveks_logo.png" alt="vivekflowers" />

                  </div>

                  <h4>Hello! let's get started</h4>

                  <h6 class="font-weight-light">Sign in to continue.</h6>

                  <form class="pt-3" onSubmit={handleSubmit}>

                    <div class="form-group">

                      <input type="email" class="form-control form-control-lg" id="exampleInputEmail1" placeholder="Username" value={email} onChange={(e) => setEmail(e.target.value)} />

                    </div>

                    <div class="form-group">

                      <input type="password" class="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />

                    </div>

                    {error && <div className="text-danger mb-3">{error}</div>}

                    <div class="mt-3 d-grid gap-2">

                      <button type="submit" class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">SIGN IN</button>

                    </div>

                    <div class="my-2 d-flex justify-content-between align-items-center">

                      <div class="form-check">

                        <label class="form-check-label text-muted">

                          <input type="checkbox" class="form-check-input" /> Keep me signed in </label>

                      </div>

                      <a href="#" class="auth-link text-black">Forgot password?</a>

                    </div>

                  </form>

                </div>

              </div>

            </div>

          </div>

        </div>

      </div>

    </>

  )
}
export default Login;