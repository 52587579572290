import React from 'react';


const Sidebar = () => {
  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <>
          {/* Dashboard */}
          <li className="nav-item">
            <a className="nav-link" href="/dashboard">
              <i class="icon-grid menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </a>
          </li>
          {/* end of the Dashboard */}

          {/* Products */}
          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="collapse" href="#products-collapse" aria-expanded="false" aria-controls="products-collapse">
              <i class="icon-layout menu-icon"></i>
              <span className="menu-title">Products</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="products-collapse">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <a className="nav-link" href="/addproduct">Add Products</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/products">View Products</a>
                </li>
              </ul>
            </div>
          </li>
          {/* End of Products */}

          {/* Orders */}
          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="collapse" href="#orders-collapse" aria-expanded="false" aria-controls="orders-collapse">
              <i class="icon-bar-graph menu-icon"></i>
              <span className="menu-title">Orders</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="orders-collapse">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <a className="nav-link" href="/orders">View Orders</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/weekly-orders">Weekly Orders</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/unpaid-orders">Cart Orders</a>
                </li>
              </ul>
            </div>
          </li>
          {/* End of Orders */}

          {/* Panchangs */}
          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="collapse" href="#panchangs-collapse" aria-expanded="false" aria-controls="panchangs-collapse">
              <i class="mdi mdi-apple-keyboard-command menu-icon"></i>
              <span className="menu-title">Panchangs</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="panchangs-collapse">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <a className="nav-link" href="/addpanchangs">Add Panchangs</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/panchangs">View Panchangs</a>
                </li>
              </ul>
            </div>
          </li>
          {/* end of the Panchangs */}

          {/* Festivals */}
          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="collapse" href="#festivals-collapse" aria-expanded="false" aria-controls="festivals-collapse">
              <i class="mdi mdi-brightness-5 menu-icon"></i>
              <span className="menu-title">Festivals</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="festivals-collapse">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <a className="nav-link" href="/addfestivals">Add Festivals</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/festivals">View Festivals</a>
                </li>
              </ul>
            </div>
          </li>
          {/* Festivals end */}

          {/* Holidays */}
          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="collapse" href="#holidays-collapse" aria-expanded="false" aria-controls="holidays-collapse">
              <i class="mdi mdi-calendar-remove menu-icon"></i>
              <span className="menu-title">Shipping Holidays</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="holidays-collapse">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <a className="nav-link" href="/addholidays">Add Holidays</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/holidays">View Holidays</a>
                </li>
              </ul>
            </div>
          </li>
          {/* Holidays end */}

          {/* Support user */}
          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="collapse" href="#users-collapse" aria-expanded="false" aria-controls="users-collapse">
              <i class="mdi mdi-account-key menu-icon"></i>
              <span className="menu-title">Support Users</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="users-collapse">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <a className="nav-link" href="/addsupportusers">Add Support Users</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/supportusers">View Support Users</a>
                </li>
              </ul>
            </div>
          </li>
          {/* Support user end */}

          {/* Sub Category */}
          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="collapse" href="#subcategory-collapse" aria-expanded="false" aria-controls="subcategory-collapse">
              <i class="mdi mdi-format-line-style menu-icon"></i>
              <span className="menu-title">Subcategories</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="subcategory-collapse">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <a className="nav-link" href="/addsubcategory">Add Subcategory</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/subcategory">View SubCategory</a>
                </li>
              </ul>
            </div>
          </li>
          {/* end of the Sub category */}

          {/* Gallery */}
          <li className="nav-item">
            <a className="nav-link" href="/gallery">
              <i class="mdi mdi-folder-image menu-icon"></i>
              <span className="menu-title">Gallery</span>
            </a>
          </li>
          {/* end of the gallery */}

          {/* Post */}
          <li className="nav-item">
            <a className="nav-link" href="/post">
              <i class="mdi mdi-folder-image menu-icon"></i>
              <span className="menu-title">Post</span>
            </a>
          </li>
          {/* Post end */}
          {/* Banner */}
          <li className="nav-item">
            <a className="nav-link" href="/banner">
              <i class="mdi mdi-folder-image menu-icon"></i>
              <span className="menu-title">Banner</span>
            </a>
          </li>
          {/* Banner end */}

          
          {/* <li className="nav-item">
            <a className="nav-link" data-bs-toggle="collapse" href="#reports-collapse" aria-expanded="false" aria-controls="reports-collapse">
              <i class="mdi mdi-format-line-style menu-icon"></i>
              <span className="menu-title">Reports</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="reports-collapse">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <a className="nav-link" href="/reports">Monthly Sales</a>
                </li>
               
              </ul>
            </div>
          </li> */}
         
        </>
      </ul>
    </nav>
  );
};

export default Sidebar;
