import React, { useEffect, useState } from 'react';
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";


const AddGallery = () => {
    
    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);

    const [gallery, setGallery] = useState([]);
    const [galleryData, setGalleryData] = useState({
        name: "",
        icon: "",
        displayOrder: "",
        enable: 1
    });
    console.log(galleryData);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setGalleryData({
            ...galleryData, [name]: value,

        });
    };

    const handleFileChange = (e) => {
        setGalleryData({ ...galleryData, icon: e.target.files[0] });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        Object.entries(galleryData).forEach(([key, value]) => {
            formData.append(key, value);
        });


        try {
            const response = await axios.post("https://api.vivekflowers.com/api/gallery", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            toast.success("Festival added successfully!", {
                position: "top-right",
            });
            navigate(`/gallery`);
            console.log(response.data);

            setGalleryData({
                name: "",
                icon: "",
                displayOrder: "",
                enable: ""
            });
        } catch (error) {
            console.error("Error adding product:", error);
            alert("Failed to add product. Please try again.");
        }
    };


    const handleCancel = () => {
        setGalleryData({
            name: "",
            icon: "",
            displayOrder: "",
            enable: ""
        });
    }

    return (
        <div className="container-scroller">

            <div className="container-fluid page-body-wrapper">

                <Header />

                <Sidebar />

                <div className="main-panel">

                    <div className="content-wrapper">

                        <div className="row">

                            <div className="col-lg-12 grid-margin stretch-card">

                                <div className="card">

                                    <div className="card-body">

                                        <div className="col-12 grid-margin stretch-card">

                                            <div className="card">

                                                <div className="card-body">

                                                    <h4 className="card-title">Add Gallery</h4>

                                                    <form className="forms-sample" onSubmit={handleSubmit}>

                                                        <div className="row">


                                                            {/* Gallery Name */}
                                                            <div className="col-md-4">

                                                                <div className="form-group">

                                                                    <label htmlFor="galleryname">Gallery Name</label>

                                                                    <input type="text" className="form-control" name="name" value={galleryData.name} onChange={handleChange} placeholder="Enter Gallery Name..." required />

                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="displayOrder">Display Order</label>
                                                                    <select
                                                                        className="form-control"
                                                                        name="displayOrder"
                                                                        value={galleryData.displayOrder}
                                                                        onChange={handleChange}
                                                                        required
                                                                    >
                                                                        <option value="" disabled>Select Display Order</option>
                                                                        <option value="1">1</option>
                                                                        <option value="2">2</option>
                                                                        <option value="3">3</option>
                                                                        <option value="4">4</option>
                                                                        <option value="4">5</option>
                                                                        <option value="4">6</option>
                                                                    </select>

                                                                </div>
                                                            </div>

                                                            {/* Image Upload */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="image">Upload Image</label>
                                                                    <input
                                                                        type="file"
                                                                        name="image_url"
                                                                        className="form-control"
                                                                        onChange={handleFileChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <button type="submit" className="btn btn-primary btn-icon-text mr-2">
                                                            <i className="ti-file btn-icon-prepend"></i> Submit
                                                        </button>
                                                        <button onClick={handleCancel}
                                                            type="button"
                                                            className="btn btn-primary btn-icon-text"
                                                            style={{ marginLeft: '10px', height: '45px', width: '120px' }}>
                                                            <i className="ti-close btn-icon-prepend"></i>

                                                            Cancel
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddGallery;
