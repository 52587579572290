import React, { useEffect, useState } from 'react';
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";


const AddFestivals = () => {

    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
        navigate('/');
        }
    }, [user]);

    const [formData, setFormData] = useState({
        date: "",
        name: "",
    });
    console.log(formData);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData, [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post("https://api.vivekflowers.com/api/festivals", formData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            toast.success("Festival added successfully!", {
                position: "top-right",
            });
            console.log(response.data);
            setFormData({
                date: "",
                name: "",
            });
            setTimeout(() => {
                navigate("/festivals");
            }, 2000);
        } catch (error) {
            console.error("Error adding festival:", error);
            toast.error("Failed to add festival. Please try again.", {
                position: "top-right",
            });
        }
    };

    const handleCancel = () => {
        setFormData({
            date: "",
            name: "",
        });
    }

    return (
        <div className="container-scroller">

            <div className="container-fluid page-body-wrapper">

                <Header />

                <Sidebar />

                <div className="main-panel">

                    <div className="content-wrapper">

                        <div className="row">

                            <div className="col-lg-12 grid-margin stretch-card">

                                <div className="card">

                                    <div className="card-body">

                                        <div className="col-12 grid-margin stretch-card">

                                            <div className="card">

                                                <div className="card-body">

                                                    <h4 className="card-title">Add Panchangs</h4>

                                                    <form className="forms-sample" onSubmit={handleSubmit}>

                                                        <div className="row">

                                                            {/*  date */}

                                                            <div className="col-md-6">

                                                                <div className="form-group">

                                                                    <label htmlFor="Date">Date</label>

                                                                    <input type="date" className="form-control" name="date" value={formData.date} onChange={handleChange} required />

                                                                </div>

                                                            </div>

                                                            {/* Name */}
                                                            <div className="col-md-6">

                                                                <div className="form-group">

                                                                    <label htmlFor="name">Name</label>

                                                                    <input type="text" className="form-control" name="name" value={formData.name} onChange={handleChange} placeholder="Enter Name..." required />

                                                                </div>
                                                            </div>



                                                        </div>

                                                        <button type="submit" className="btn btn-primary btn-icon-text mr-2">
                                                            <i className="ti-file btn-icon-prepend"></i> Submit
                                                        </button>
                                                        <button onClick={handleCancel}
                                                            type="button"
                                                            className="btn btn-primary btn-icon-text"
                                                            style={{ marginLeft: '10px', height: '45px', width: '120px' }}>
                                                            <i className="ti-close btn-icon-prepend"></i>

                                                            Cancel
                                                        </button>
                                                    </form>
                                                    <ToastContainer />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddFestivals;
