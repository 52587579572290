import React, { useEffect, useState } from 'react';
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import axios from 'axios';
import { Pagination, Stack, Select, MenuItem, FormControl } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const Festivals = () => {

    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);
    
    const [festivals, setFestivals] = useState([]);
    const [search, setSearch] = useState('');
    const [rowsperpage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
   

    const handleEdit = (festivals) => {
        navigate("/editfestivals", { state: { festivals } });
    };


    const handleRemove = (id, currentRemoveStatus) => {
        axios
            .put(`https://api.vivekflowers.com/api/festivals/remove/${id}`, {
                remove: currentRemoveStatus === 1 ? 0 : 1,
            })
            .then((response) => {
                alert(response.data.message || "Festivals Deleted Sucessfully");
                fecthFestivals(currentPage);
            })
            .catch((error) => console.error('Error updating remove status:', error));
    };


    const fecthFestivals = (page = 1) => {
        axios
            .get(`https://api.vivekflowers.com/api/festivals?page=${page}&per_page=${rowsperpage}`)
            .then((response) => {
                setFestivals(response.data.festivals || []);
                setTotalPages(response.data.pagination.total_pages || 1);
            })
            .catch((error) => console.error('Error fetching products:', error));
    };

    const handlePageChange = (e, page) => {
        setCurrentPage(page);
    };


    useEffect(() => {
        fecthFestivals(currentPage);
    }, [currentPage, rowsperpage]);

    const filteredFestivals = festivals.filter((festival) => {
        const searchTerm = search.toLowerCase();
        return (
            festival.name.toLowerCase().includes(searchTerm) ||
            festival.date.toLowerCase().includes(searchTerm) ||
            festival.id.toString().includes(searchTerm)
        );
    });


    return (
        <div className="container-scroller">

            <Header />

            <div className="container-fluid page-body-wrapper">

                <Sidebar />

                <div className="main-panel">

                    <div className="content-wrapper">

                        <div className="row">

                            <div className="col-lg-12 grid-margin stretch-card">

                                <div className="card">

                                    <div className="card-body">

                                        <div className="d-flex justify-content-between align-items-center">

                                            <h4 className="card-title">Festivals</h4>

                                            <input type="text" className="form-control w-25 responsive-search-bar" placeholder="Search products..." value={search} onChange={(e) => setSearch(e.target.value)} style={{ maxWidth: "300px", height: '40px' }} />

                                        </div>

                                        <div className="table-responsive mt-4">

                                            <table className="table">

                                                <thead>

                                                    <tr>

                                                        <th>ID</th>
                                                        <th>Date</th>
                                                        <th>Name</th>
                                                        <th>Action</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredFestivals.map((festival, index) => (
                                                        <tr key={index}>
                                                            <td>{festival.id}</td>
                                                            <td>{festival.date}</td>
                                                            <td>{festival.name}</td>
                                                            <td>
                                                                <label className="badge badge-danger pointer" style={{ marginRight: "10px" }}>

                                                                    <i className="mdi mdi-delete" style={{ fontSize: "18px" }} onClick={() => handleRemove(festival.id, festival.remove)}></i>
                                                                    <ToastContainer />

                                                                </label>

                                                                <label className="badge badge-secondary pointer">

                                                                    <i a className="mdi mdi-storefront-edit" onClick={() => handleEdit(festival)} style={{ fontSize: "18px" }}></i>

                                                                </label>

                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                        </div>
                                        {/* Pagination and Rows per Page */}
                                        <div className="d-flex flex-wrap justify-content-between align-items-center p-2">

                                            <FormControl variant="outlined" size="small" style={{ marginRight: '10px', marginBottom: '10px', minWidth: '80px' }} >
                                                <Select labelId="rows-per-page-label" value={rowsperpage} onChange={(e) => { setRowsPerPage(e.target.value); setCurrentPage(1); }}>
                                                    <MenuItem value={5}>5</MenuItem>
                                                    <MenuItem value={10}>10</MenuItem>
                                                    <MenuItem value={15}>15</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} variant="outlined" color="primary" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Festivals;
