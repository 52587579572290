import React, { useEffect, useState } from 'react';
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const AddSupportUsers = () => {

    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);

    const [formData, setFormData] = useState({

        email: "",
        password: "",

    });
    //console.log(formData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData, [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();


        try {
            const response = await axios.post("https://api.vivekflowers.com/api/user", formData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            alert("Supporting Users added successfully!");
            navigate(`/supportusers`);
            //console.log(response.data);

            setFormData({
                email: "",
                password: "",
                name: "",

            });
        } catch (error) {
            console.error("Error adding Support users:", error);
            alert("Failed to add Support Users. Please try again.");
        }
    };

    const handleCancel = () => {
        setFormData({
            email: "",
            password: "",
            name: "",
        });
    }

    return (
        <div className="container-scroller">

            <div className="container-fluid page-body-wrapper">

                <Header />

                <Sidebar />

                <div className="main-panel">

                    <div className="content-wrapper">

                        <div className="row">

                            <div className="col-lg-12 grid-margin stretch-card">

                                <div className="card">

                                    <div className="card-body">

                                        <div className="col-12 grid-margin stretch-card">

                                            <div className="card">

                                                <div className="card-body">

                                                    <h4 className="card-title">Add Supporting Users</h4>

                                                    <form className="forms-sample" onSubmit={handleSubmit}>

                                                        <div className="row">

                                                            {/* {/*email */}

                                                            <div className="col-md-4">

                                                                <div className="form-group">

                                                                    <label htmlFor="email">Name</label>

                                                                    <input type="text" className="form-control" name="name" value={formData.name} onChange={handleChange} placeholder='Enter Name' required />

                                                                </div>

                                                            </div>

                                                            <div className="col-md-4">

                                                                <div className="form-group">

                                                                    <label htmlFor="email">Email</label>

                                                                    <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} placeholder='Enter Email' required />

                                                                </div>

                                                            </div>

                                                            {/* Password */}

                                                            <div className="col-md-4">

                                                                <div className="form-group">

                                                                    <label htmlFor="password">Password</label>

                                                                    <input type="password" className="form-control" name="password" value={formData.password} onChange={handleChange} placeholder='Enter Password' required />

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="col-md-12" align="center">
                                                            <button type="submit" className="btn btn-primary btn-icon-text mr-2">
                                                            <i className="ti-file btn-icon-prepend"></i> Submit
                                                            </button>
                                                            <button onClick={handleCancel}
                                                                type="button"
                                                                className="btn btn-secondary btn-icon-text"
                                                                style={{ marginLeft: '10px', height: '45px', width: '120px' }}>
                                                                <i className="ti-close btn-icon-prepend"></i>

                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddSupportUsers;
