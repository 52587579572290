import React, { useEffect, useState } from 'react';
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const AddPanchangs = () => {

    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);

    const [formData, setFormData] = useState({
        date: "",
        paksham: "",
        rahukal: "",
        sunrise: "",
        sunset: "",
        tidi: "",
        yamaganda: ""

    });
    console.log(formData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData, [name]: value,
        });
    };
   
    const handleSubmit = async (e) => {
        e.preventDefault();


        try {
            const response = await axios.post("https://api.vivekflowers.com/api/panchang", formData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            alert("Panchangs added successfully!");
            navigate(`/panchangs`);
            console.log(response.data);

            setFormData({
                date: "",
                paksham: "",
                rahukal: "",
                sunrise: "",
                sunset: "",
                tidi: "",
                yamaganda: ""
            });
        } catch (error) {
            console.error("Error adding product:", error);
            alert("Failed to add panchangs. Please try again.");
        }
    };

    const handleCancel = () => {
        setFormData({
            date: "",
            paksham: "",
            rahukal: "",
            sunrise: "",
            sunset: "",
            tidi: "",
            yamaganda: ""
        });
    }

    return (
        <div className="container-scroller">

            <div className="container-fluid page-body-wrapper">

                <Header />

                <Sidebar />

                <div className="main-panel">

                    <div className="content-wrapper">

                        <div className="row">

                            <div className="col-lg-12 grid-margin stretch-card">

                                <div className="card">

                                    <div className="card-body">

                                        <div className="col-12 grid-margin stretch-card">

                                            <div className="card">

                                                <div className="card-body">

                                                    <h4 className="card-title">Add Panchangs</h4>

                                                    <form className="forms-sample" onSubmit={handleSubmit}>

                                                        <div className="row">

                                                            {/*  ID */}

                                                            <div className="col-md-3">

                                                                <div className="form-group">

                                                                    <label htmlFor="Date">Date</label>

                                                                    <input type="date" className="form-control" name="date"  value={formData.date} onChange={handleChange} required />

                                                                </div>

                                                            </div>

                                                            {/* Sun Rise */}
                                                            <div className="col-md-3">

                                                                <div className="form-group">

                                                                    <label htmlFor="sunrise">Sun Rise</label>

                                                                    <input type="text" className="form-control" name="sunrise" value={formData.sunrise} onChange={handleChange} placeholder="Enter Sun Rise..." required />

                                                                </div>
                                                            </div>

                                                            {/* Sun set */}
                                                            <div className="col-md-3">

                                                                <div className="form-group">

                                                                    <label htmlFor="sunset">Sun Set</label>

                                                                    <input type="text" className="form-control" name="sunset" value={formData.sunset} onChange={handleChange} placeholder="Enter Sun Set..." required />

                                                                </div>
                                                            </div>

                                                            {/* Rahukal */}
                                                            <div className="col-md-3">

                                                                <div className="form-group">

                                                                    <label htmlFor="rahukal">Rahukal</label>

                                                                    <input type="text" className="form-control" name="rahukal" value={formData.rahukal} onChange={handleChange} placeholder="Enter Rahukal..." required />

                                                                </div>
                                                                
                                                            </div>

                                                            {/* YamaGanda */}
                                                            <div className="col-md-3">

                                                                <div className="form-group">

                                                                    <label htmlFor="yamaganda">YamaGanda</label>

                                                                    <input type="text" className="form-control" name="yamaganda" value={formData.yamaganda} onChange={handleChange} placeholder="Enter Yamaganda..." required />

                                                                </div>
                                                                
                                                            </div>

                                                            {/* Tidi */}


                                                            <div className="col-md-3">

                                                                <div className="form-group">

                                                                    <label htmlFor="tidi">Tidi</label>

                                                                    <input type="text" className="form-control" name="tidi" value={formData.tidi} onChange={handleChange} placeholder="Enter Tidi..." required />

                                                                </div>
                                                                
                                                            </div>

                                                            {/* Paksham */}
                                                            <div className="col-md-3">

                                                                <div className="form-group">

                                                                    <label htmlFor="pakshami">Paksham</label>

                                                                    <input type="text" className="form-control" name="paksham" value={formData.paksham} onChange={handleChange} placeholder="Enter Tidi..." required />

                                                                </div>
                                                                
                                                            </div>
                            
                                                        </div>

                                                        <button type="submit" className="btn btn-primary btn-icon-text mr-2">
                                                            <i className="ti-file btn-icon-prepend"></i> Submit
                                                        </button>
                                                        <button onClick={handleCancel}
                                                            type="button"
                                                            className="btn btn-primary btn-icon-text"
                                                            style={{ marginLeft: '10px', height: '45px', width: '120px' }}>
                                                            <i className="ti-close btn-icon-prepend"></i>

                                                            Cancel
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddPanchangs;
