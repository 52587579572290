import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import { useNavigate, useLocation } from "react-router-dom";

const EditGallery = () => {

    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);

    const location = useLocation();
    const gallery = location.state?.gallery;

    const [galleryData, setGalleryData] = useState(gallery);
    const [selectedImage, setSelectedImage] = useState(gallery?.icon);

    useEffect(() => {
        if (gallery && gallery.icon) {
            setSelectedImage(gallery.icon);
        }
    }, [gallery]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setGalleryData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleFileChange = (e) => {
        setGalleryData((prevState) => ({
            ...prevState,
            icon: e.target.files[0],
        }));

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { id, ...updatedGallery } = galleryData;
        const formData = new FormData();
        Object.entries(updatedGallery).forEach(([key, value]) => {
            formData.append(key, value);
        });

        axios
            .put(`https://api.vivekflowers.com/api/gallery/${id}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                alert(response.data.message);
                navigate("/gallery");
            })
            .catch((error) => {
                console.error("Error updating gallery:", error);
                alert("Failed to update gallery. Please try again.");
            });
    };

    const handleCancel = () => {
        setGalleryData({
            name: "",
            icon: "",
            displayOrder: "",
            enable: "",
        });
        setSelectedImage(null);
    };

    return (
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper">
                <Header />
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-12 grid-margin stretch-card">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h4 className="card-title">Edit Gallery</h4>
                                                    <form className="forms-sample" onSubmit={handleSubmit}>
                                                        <div className="row">
                                                            {/* Gallery Name */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="galleryname">Gallery Name</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="name"
                                                                        value={galleryData.name}
                                                                        onChange={handleChange}
                                                                        placeholder="Enter Gallery Name..."
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>

                                                            {/* Display Order */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="displayOrder">Display Order</label>
                                                                    <select
                                                                        className="form-control"
                                                                        name="displayOrder"
                                                                        value={galleryData.displayOrder}
                                                                        onChange={handleChange}
                                                                        required
                                                                    >
                                                                        <option value="" disabled>
                                                                            Select Display Order
                                                                        </option>
                                                                        <option value="1">1</option>
                                                                        <option value="2">2</option>
                                                                        <option value="3">3</option>
                                                                        <option value="4">4</option>
                                                                        <option value="5">5</option>
                                                                        <option value="6">6</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            {/* Image Upload */}
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="icon">Upload Image</label>
                                                                    <input
                                                                        type="file"
                                                                        name="icon"
                                                                        className="form-control"
                                                                        onChange={handleFileChange}
                                                                    />
                                                                    
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button type="submit" className="btn btn-primary btn-icon-text mr-2">
                                                            <i className="ti-file btn-icon-prepend"></i> Submit
                                                        </button>
                                                        <button
                                                            onClick={handleCancel}
                                                            type="button"
                                                            className="btn btn-primary btn-icon-text"
                                                            style={{ marginLeft: "10px", height: "45px", width: "120px" }}
                                                        >
                                                            <i className="ti-close btn-icon-prepend"></i> Cancel
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
    );
};

export default EditGallery;
