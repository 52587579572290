import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import { useNavigate, useLocation } from "react-router-dom";

const EditUsers = () => {
    
    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);

    const location = useLocation();
    const users = location.state?.users;

    const [formData, setFormData] = useState(
        users || {
            email: "",
            password: "",
            status: "",
            name: "",
        }
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { user_id, ...updatedProduct } = formData;
        axios
            .put(`https://api.vivekflowers.com/api/user/${user_id}`, updatedProduct)
            .then((response) => {
                alert(response.data.message || "Supporting Users updated successfully.");
                navigate("/supportusers");
            })
            .catch((error) => {
                console.error("Error updating Supportusers:", error);
                alert("Failed to update Supportusers. Please try again.");
            });
    };

    const handleCancel = () => {
        setFormData({
            email: "",
            password: "",
            status: "",
            name: "",

        });
    }

    return (
        <div className="container-scroller">

            <div className="container-fluid page-body-wrapper">

                <Header />

                <Sidebar />

                <div className="main-panel">

                    <div className="content-wrapper">

                        <div className="row">

                            <div className="col-lg-12 grid-margin stretch-card">

                                <div className="card">

                                    <div className="card-body">

                                        <div className="col-12 grid-margin stretch-card">

                                            <div className="card">

                                                <div className="card-body">

                                                    <h4 className="card-title">Edit Supporting Users</h4>

                                                    <form className="forms-sample" onSubmit={handleSubmit}>

                                                        <div className="row">

                                                            {/* {/*email */}

                                                            <div className="col-md-4">

                                                                <div className="form-group">

                                                                    <label htmlFor="name">Name</label>

                                                                    <input type="text" className="form-control" name="name" value={formData.name} onChange={handleChange} placeholder='Enter Email' required />

                                                                </div>

                                                            </div>

                                                            <div className="col-md-4">

                                                                <div className="form-group">

                                                                    <label htmlFor="email">Email</label>

                                                                    <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} placeholder='Enter Email' required />

                                                                </div>

                                                            </div>

                                                            {/* Password */}

                                                            <div className="col-md-4">

                                                                <div className="form-group">

                                                                    <label htmlFor="password">Password</label>

                                                                    <input type="password" className="form-control" name="password" value={formData.password} onChange={handleChange} placeholder='Enter Password' required />

                                                                </div>

                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="status">Status</label>
                                                                    <select
                                                                        className="form-control"
                                                                        name="status"
                                                                        value={formData.status}
                                                                        onChange={handleChange}
                                                                        required
                                                                    >
                                                                        <option value="">Select Status</option>
                                                                        <option value="Active">Active</option>
                                                                        <option value="In Active">In Active</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <button type="submit" className="btn btn-primary btn-icon-text mr-2">
                                                            <i className="ti-file btn-icon-prepend"></i> Submit
                                                        </button>
                                                        <button onClick={handleCancel}
                                                            type="button"
                                                            className="btn btn-primary btn-icon-text"
                                                            style={{ marginLeft: '10px', height: '45px', width: '120px' }}>
                                                            <i className="ti-close btn-icon-prepend"></i>

                                                            Cancel
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div >
        </div >
    );
}
export default EditUsers;
