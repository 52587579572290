import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from "./pages/dashboard";
import Product from "./pages/products";
import AddProduct from "./pages/addproduct";
import EditProduct from "./pages/editproduct";
import Login from "./pages/login";
import Orders from "./pages/orders";
import OrderDetails from './pages/orderDetails';
import AddSubcategory from "./pages/addsubcategory";
import Gallery from "./pages/gallery";
import AddGallery from "./pages/addgallery";
import EditGallery from "./pages/editgallery";
import Panchangs from "./pages/panchangs";
import Festivals from "./pages/festivals";
import ShippingHolidays from "./pages/shippingholidays";
import SupportUsers from "./pages/supportuser";
import AddPanchangs from "./pages/addpanchags";
import EditPanchags from "./pages/editpanchangs";
import AddFestivals from "./pages/addfestivals";
import EditFestivals from "./pages/editfestivals";
import AddSupportUsers from "./pages/addusers";
import EditUsers from "./pages/editusers";
import AddHolidays from "./pages/addholidays";
import EditHolidays from "./pages/editholidays";
import Post from "./pages/post";
import AddPost from "./pages/addpost";
import EditPost from "./pages/editpost";
import PickupSaleChart from "./pages/test";
import PrivateRoute from "./pages/route";
import SubCategory from "./pages/subcategory";
import EditSubCategory from "./pages/editsubcategory";
import WeekOrders from "./pages/weeklyOrders";
import Banners from "./pages/banner/banner";
import AddBanners from "./pages/banner/addbanner";
import EditBanners from "./pages/banner/editbanner";
import UnPaids from "./pages/reports/unPaids";
import Reports from "./pages/reports/report";

import Test from "./pages/test";



const App = () => {

  return (

    <>
      <div>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/products" element={<PrivateRoute><Product /></PrivateRoute>} />
            <Route path="/addproduct" element={<AddProduct />} />
            <Route path="/editproduct" element={<EditProduct />} />
            <Route path="orders" element={<PrivateRoute><Orders /></PrivateRoute>} />
            <Route path="/orderdetails" element={<OrderDetails />} />
            <Route path="/addsubcategory" element={<AddSubcategory />} />
            <Route path="/subcategory" element={<SubCategory />} />
            <Route path="/editsubcategory" element={<EditSubCategory />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/addgallery" element={<AddGallery />} />
            <Route path="/addpost" element={<AddPost />} />
            <Route path="/editpost" element={<EditPost />} />
            <Route path="/post" element={<Post />} />
            <Route path="/editgallery" element={<EditGallery />} />
            <Route path="/panchangs" element={<Panchangs />} />
            <Route path="/addpanchangs" element={<AddPanchangs />} />
            <Route path="/editpanchangs" element={<EditPanchags />} />
            <Route path="/festivals" element={<Festivals />} />
            <Route path="/addfestivals" element={<AddFestivals />} />
            <Route path="/editfestivals" element={<EditFestivals />} />
            <Route path="/holidays" element={<ShippingHolidays />} />
            <Route path="/addholidays" element={<AddHolidays />} />
            <Route path="/editholidays" element={<EditHolidays />} />
            <Route path="/supportusers" element={<SupportUsers />} />
            <Route path="/addsupportusers" element={<AddSupportUsers />} />
            <Route path="/editsupportusers" element={<EditUsers />} />
            <Route path="/sales" element={<PickupSaleChart />} />
            <Route path="/weekly-orders" element={<WeekOrders />} />
            <Route path="/banner" element={<Banners />} />
            <Route path="/edit-banner" element={<EditBanners />} />
            <Route path="/add-banner" element={<AddBanners />} />
            <Route path="/unpaid-orders" element={<UnPaids />} />
            <Route path="/reports" element={<Reports />} />

            <Route path="/test" element={<Test />} />

          </Routes>
        </Router>


      </div>

    </>
  )
}

export default App;