import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import { useNavigate, useLocation } from "react-router-dom";

const EditHolidays = () => {

    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);

    const location = useLocation();
    const holidays = location.state?.holidays;

    const [formData, setFormData] = useState(
        holidays || {
            date: "",
        }
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { id, ...updatedProduct } = formData;
        axios
            .put(`https://api.vivekflowers.com/api/shippingholidays/${id}`, updatedProduct)
            .then((response) => {
                alert(response.data.message || "Shipping Holidays updated successfully.");
                navigate("/holidays");
            })
            .catch((error) => {
                console.error("Error updating Shipping Holidays :", error);
                alert("Failed to update Shipping Holidays. Please try again.");
            });
    };

    const handleCancel = () => {
        setFormData({

            date: "",
           
        });
    }
    return (
        <div className="container-scroller">

            <div className="container-fluid page-body-wrapper">

                <Header />

                <Sidebar />

                <div className="main-panel">

                    <div className="content-wrapper">

                        <div className="row">

                            <div className="col-lg-12 grid-margin stretch-card">

                                <div className="card">

                                    <div className="card-body">

                                        <div className="col-12 grid-margin stretch-card">

                                            <div className="card">

                                                <div className="card-body">

                                                    <h4 className="card-title">Edit Holidays</h4>

                                                    <form className="forms-sample" onSubmit={handleSubmit}>

                                                        <div className="row">

                                                            {/*  date */}

                                                            <div className="col-md-6">

                                                                <div className="form-group">

                                                                    <label htmlFor="Date">Date</label>

                                                                    <input type="date" className="form-control" name="date" value={formData.date} onChange={handleChange} required />

                                                                </div>

                                                            </div>

                                                        </div>

                                                        <button type="submit" className="btn btn-primary btn-icon-text mr-2">
                                                            <i className="ti-file btn-icon-prepend"></i> Submit
                                                        </button>
                                                        <button onClick={handleCancel}
                                                            type="button"
                                                            className="btn btn-primary btn-icon-text"
                                                            style={{ marginLeft: '10px', height: '45px', width: '120px' }}>
                                                            <i className="ti-close btn-icon-prepend"></i>

                                                            Cancel
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
    );
};

export default EditHolidays;