import React, { useEffect, useState } from 'react';
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import axios from 'axios';
import { Pagination, Stack, Select, MenuItem, FormControl } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Product = () => {

    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);

    const [products, setProducts] = useState([]);
    const [variations, setVariations] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [search, setSearch] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubcategory, setSelectedSubcategory] = useState('');
    const [rowsperpage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    //console.log(products)




    const fetchCategories = () => {
        axios
            .get('https://api.vivekflowers.com/api/category')
            .then(response => setCategories(response.data))
            .catch(error => console.error('Error fetching categories:', error));
    };


    const fetchSubcategories = (categoryId) => {
        axios
            .get(`https://api.vivekflowers.com/api/subcategory/${categoryId}`)
            .then(response => setSubcategories(response.data))
            .catch(error => console.error('Error fetching subcategories:', error));
    };


    const fetchProductsPages = () => {
        const params = {
            page: currentPage,
            per_page: rowsperpage,
            search,
        };

        if (!search) {
            params.category = selectedCategory;
            params.subcategory = selectedSubcategory;
        }


        axios
            .get('https://api.vivekflowers.com/api/all-products', { params })
            .then(response => {
                setProducts(response.data.products);
                setTotalPages(response.data.pagination.total_pages || 1);
            })
            .catch(error => console.error('Error fetching products:', error));
    };

    // const FetchVariations = (variation_id) => {
    //     axios.get(`https://167.88.42.252:3000/api/variations/${variation_id}`,) 
    //         .then(response => {
    //             setVariations(response.data.products.variation);
    //             console.log(variations);
    //         })
    //         .catch(error => console.error('Error Fetching Variations', error));
    //     }

    const handleSearch = () => {
        setCurrentPage(1);
        fetchProductsPages();
    };

    useEffect(() => {
        handleSearch();
    }, [search, selectedCategory, selectedSubcategory]);

    const handleCategoryChange = (e) => {
        const category = categories.find(item => item.name === e.target.value);
        setSelectedCategory(e.target.value);
        setSelectedSubcategory('');
        fetchSubcategories(category.id);
    };

    const handleSubcategoryChange = (e) => {
        setSelectedSubcategory(e.target.value);
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        fetchProductsPages();
    }, [currentPage, rowsperpage, search, selectedCategory, selectedSubcategory]);

    const handleEdit = (product) => {
        navigate("/editproduct", { state: { products: product } });
    };

    const handleVisibilityChange = (productId, currentVisibility) => {
        axios
            .patch(`https://api.vivekflowers.com/api/product/visibility/${productId}`, {
                visibility: currentVisibility === 1 ? 0 : 1,
            })
            .then((response) => {
                alert(response.data.message);
                fetchProductsPages();
            })
            .catch((error) => console.error('Error updating visibility:', error));
    };

    const handleRemove = (productId, currentRemoveStatus) => {
        axios
            .patch(`https://api.vivekflowers.com/api/product/remove/${productId}`, {
                remove: currentRemoveStatus === 1 ? 0 : 1,
            })
            .then((response) => {
                alert(response.data.message);
                fetchProductsPages();
            })
            .catch((error) => console.error('Error updating remove status:', error));
    };

    return (
        <div className="container-scroller">
            <Header />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />

                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="card-title">Products</h4>
                                            <input
                                                type="text"
                                                className="form-control w-25 responsive-search-bar"
                                                placeholder="Search products..."
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                                style={{ maxWidth: "300px", height: '40px' }}
                                            />
                                        </div>

                                        <div className="d-flex justify-content-between mt-4">
                                            <FormControl variant="outlined" size="small" style={{ marginRight: '10px', minWidth: '150px' }} >
                                                <Select
                                                    value={selectedCategory}
                                                    onChange={handleCategoryChange}
                                                    displayEmpty
                                                >
                                                    <MenuItem value="">Select Category</MenuItem>
                                                    {categories.map((category) => (
                                                        <MenuItem key={category.id} value={category.name}>
                                                            {category.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            <FormControl variant="outlined" size="small" style={{ minWidth: '150px' }} >
                                                <Select
                                                    value={selectedSubcategory}
                                                    onChange={handleSubcategoryChange}
                                                    displayEmpty
                                                    disabled={!selectedCategory}
                                                >
                                                    <MenuItem value="">Select Subcategory</MenuItem>
                                                    {subcategories.map((subcategory) => (
                                                        <MenuItem key={subcategory.id} value={subcategory.name}>
                                                            {subcategory.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>

                                        <div className="table-responsive mt-4">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Product</th>
                                                        <th>SKU</th>
                                                        {window.innerWidth > 768 && <th>Category</th>}
                                                        {window.innerWidth > 768 && <th>Sub Category</th>}
                                                        <th>Price</th>
                                                        {/* <th>Weight</th> */}
                                                        {/* <th>Weight With Ice</th> */}
                                                        {/* <th>Variation</th> */}
                                                        <th>Options</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {products.map((product) => (
                                                        <tr key={product.product_id}>
                                                            <td className="py-1">
                                                                <img src={`https://api.vivekflowers.com/${product.image_url}`} alt={product.productName} style={{ marginRight: "10px" }} />

                                                                {product.productName}
                                                            </td>

                                                            <td>{product.SKU}</td>
                                                            {window.innerWidth > 768 && <td>{product.category_name}</td>}
                                                            {window.innerWidth > 768 && <td>{product.subcategory_name}</td>}
                                                            <td>${product.amount}</td>
                                                            {/* <td>{product.weight}</td> */}
                                                            {/* <td>{product.weightWithIce}</td> */}
                                                            {/* <td>
                                                                {product.variations.map((variation) => (
                                                                    <div key={variation.variation_id}>
                                                                        <p>{variation.variation_name}</p>
                                                                        <p>{variation.variation_amount}</p>
                                                                    </div>

                                                                ))}
                                                            </td> */}
                                                            <td>
                                                                {product.visibility === 0 && (
                                                                    <label className="badge badge-danger pointer" style={{ marginRight: "10px" }} onClick={() => handleVisibilityChange(product.id, product.visibility)} >
                                                                        <i className="mdi mdi-eye-off" style={{ fontSize: "18px" }}></i>
                                                                    </label>
                                                                )}
                                                                {product.visibility === 1 && (
                                                                    <label className="badge badge-primary pointer" style={{ marginRight: "10px" }} onClick={() => handleVisibilityChange(product.id, product.visibility)} >
                                                                        <i className="mdi mdi-eye" style={{ fontSize: "18px" }}></i>
                                                                    </label>
                                                                )}
                                                                <label className="badge badge-danger pointer" style={{ marginRight: "10px" }} onClick={() => handleRemove(product.id, product.remove)} >
                                                                    <i className="mdi mdi-delete" style={{ fontSize: "18px" }}></i>
                                                                </label>
                                                                <label className="badge badge-secondary pointer" onClick={() => handleEdit(product)} >
                                                                    <i className="mdi mdi-storefront-edit" style={{ fontSize: "18px" }}></i>
                                                                </label>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                            <div className="d-flex flex-wrap justify-content-between align-items-center p-2">
                                                <FormControl variant="outlined" size="small" style={{ marginRight: '10px', marginBottom: '10px', minWidth: '80px' }} >
                                                    <Select labelId="rows-per-page-label" value={rowsperpage} onChange={(e) => { setRowsPerPage(e.target.value); setCurrentPage(1); }} >
                                                        <MenuItem value={5}>5</MenuItem>
                                                        <MenuItem value={10}>10</MenuItem>
                                                        <MenuItem value={15}>15</MenuItem>
                                                        <MenuItem value={20}>20</MenuItem>
                                                    </Select>
                                                </FormControl>

                                                <Stack spacing={2}>
                                                    <Pagination
                                                        count={totalPages}
                                                        page={currentPage}
                                                        onChange={(_, page) => { setCurrentPage(page); }}
                                                        color="primary"
                                                    />
                                                </Stack>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Product;
