import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../includes/navBar";
import Sidebar from "../../includes/sideBar";
import Footer from "../../includes/footer";
import { useNavigate, useLocation } from "react-router-dom";

const EditBanner = () => {
    
    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);
    
    const location = useLocation();
    const banner = location.state?.posts;

    const [postData, setPostData] = useState({
        id: banner?.id || "",
        name: banner?.name || "",
        enable: banner?.enable || 1,
        image: null,
        subcategory_id: banner?.subcategory_id || []
    });

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [subcategories, setSubcategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [existingImage, setExistingImage] = useState(banner?.image || null);
    const [loadingSubcategories, setLoadingSubcategories] = useState(false);

    useEffect(() => {
        if (banner) {
            setPostData({
                id: banner.id || "",
                name: banner.name || "",
                enable: banner.enable || 1,
                image: null,
                subcategory_id: banner.subcategory_id || []
            });
            setExistingImage(banner.image || null);


            if (banner.subcategory_id && banner.subcategory_id.length > 0) {
                determineCategoryFromSubcategory(banner.subcategory_id[0]);
            }
        }

        const fetchCategories = async () => {
            try {
                const response = await axios.get("https://api.vivekflowers.com/api/category");
                setCategories(response.data);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        fetchCategories();
    }, [banner]);

    const determineCategoryFromSubcategory = async (subcategoryId) => {
        try {
            const response = await axios.get(`https://api.vivekflowers.com/api/subcategory/${subcategoryId}`);
            if (response.data && response.data.category_id) {
                setSelectedCategory(response.data.category_id);
            }
        } catch (error) {
            console.error("Error determining category from subcategory:", error);
        }
    };

    useEffect(() => {
        if (selectedCategory) {
            const fetchSubcategories = async () => {
                setLoadingSubcategories(true);
                try {
                    const response = await axios.get(`https://api.vivekflowers.com/api/subcategory/${selectedCategory}`);
                    setSubcategories(response.data);

                    // If we have existing subcategory_ids, ensure they're checked
                    if (postData.subcategory_id && postData.subcategory_id.length > 0) {
                        // No need to modify state here as we're just displaying existing selections
                    }
                } catch (error) {
                    console.error("Error fetching subcategories:", error);
                } finally {
                    setLoadingSubcategories(false);
                }
            };

            fetchSubcategories();
        } else {
            setSubcategories([]);
        }
    }, [selectedCategory]);

    const handleChange = (e) => {
        setPostData({ ...postData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setPostData((prevState) => ({ ...prevState, image: file }));
        }
    };

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
        // Clear selected subcategories when category changes
        setPostData(prev => ({ ...prev, subcategory_id: [] }));
    };

    const handleSubcategorySelection = (id) => {
        setPostData((prevState) => ({
            ...prevState,
            subcategory_id: prevState.subcategory_id.includes(id)
                ? prevState.subcategory_id.filter(subId => subId !== id)
                : [...prevState.subcategory_id, id]
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("name", postData.name);
        formData.append("enable", Number(postData.enable));

        if (postData.image instanceof File) {
            formData.append("image", postData.image);
        }

        formData.append("subcategory_id", `[${postData.subcategory_id.join(',')}]`);

        try {
            const response = await axios.put(
                `https://api.vivekflowers.com/api/update-banner/${postData.id}`,
                formData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );

            alert("Banner updated successfully!");
            navigate("/banner");
        } catch (error) {
            console.error("Error updating banner:", error);
            alert("Failed to update banner. Please try again.");
        }
    };

    return (
        <div className="container-scroller">
            <Header />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">Edit Banner</h4>
                                        <form onSubmit={handleSubmit}>

                                            {/* Banner Name */}
                                            <div className="row">
                                                <div className="col-md-4 col-lg-4">
                                                    <div className="form-group">
                                                        <label>Banner Name</label>
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            className="form-control"
                                                            value={postData.name}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                {/* Existing Image Preview */}
                                                
                                                    {existingImage && (
                                                        <div className="col-md-4 col-lg-4">
                                                        <div className="form-group">
                                                            <label>Current Image</label>
                                                            <div>
                                                                <img src={`https://api.vivekflowers.com/uploads/${existingImage}`} alt="Banner" width="200" />
                                                            </div>
                                                        </div>
                                                        </div>
                                                    )}

                                                    {/* Image Upload */}
                                                    <div className="col-md-4 col-lg-4">
                                                        <div className="form-group">
                                                            <label>Upload New Image</label>
                                                            <input type="file" name="image" onChange={handleFileChange} className="form-control" />
                                                        </div>
                                                    </div>
                                                

                                                {/* Enable Toggle */}
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Enable</label>
                                                        <select name="enable" className="form-control" value={postData.enable} onChange={handleChange}>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                {/* Category Selection */}
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="category">Category</label>
                                                        <select
                                                            name="category"
                                                            className="form-control"
                                                            value={selectedCategory}
                                                            onChange={handleCategoryChange}
                                                            required
                                                        >
                                                            <option value="">Select a category</option>
                                                            {categories.map(category => (
                                                                <option key={category.id} value={category.id}>
                                                                    {category.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                {/* Subcategory Selection */}
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Subcategories</label>
                                                        {selectedCategory ? (
                                                            <>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Search subcategories..."
                                                                    value={searchTerm}
                                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                                    disabled={loadingSubcategories}
                                                                />
                                                                {loadingSubcategories ? (
                                                                    <p>Loading subcategories...</p>
                                                                ) : (
                                                                    <div style={{ maxHeight: "200px", overflowY: "auto", border: "1px solid #ccc", borderRadius: "5px", padding: "5px", marginTop: "5px" }}>
                                                                        <ul className="list-group">
                                                                            {subcategories
                                                                                .filter(sub => sub.name.toLowerCase().includes(searchTerm.toLowerCase()))
                                                                                .map(sub => (
                                                                                    <li key={sub.id} className="list-group-item">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={postData.subcategory_id.includes(sub.id)}
                                                                                            onChange={() => handleSubcategorySelection(sub.id)}
                                                                                        />
                                                                                        {" " + sub.name}
                                                                                    </li>
                                                                                ))}
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <p>Please select a category first</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                <button type="submit" className="btn btn-primary">Update Banner</button>
                                                </div>
                                                
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default EditBanner;