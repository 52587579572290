import React, { useEffect, useState } from 'react';
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import axios from 'axios';
import { Pagination, Select, MenuItem, FormControl } from '@mui/material';
import { Modal, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ShippingHolidays = () => {

    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);

    const [holidays, setHolidays] = useState([]);
    const [search, setSearch] = useState('');
    const [rowsperpage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [formData, setFormData] = useState({ date: "", id: "" });
   
    
    


    const handleRemove = (id, currentRemoveStatus) => {
        axios
            .put(`https://api.vivekflowers.com/api/shippingholidays/remove/${id}`, {
                remove: currentRemoveStatus === 1 ? 0 : 1,
            })
            .then((response) => {
                alert(response.data.message);
                fecthHolidays(currentPage);
            })
            .catch((error) => console.error('Error updating remove status:', error));
    };
    const fecthHolidays = (page = 1) => {
        axios
            .get(`https://api.vivekflowers.com/api/all-shippingholidays?page=${page}&per_page=${rowsperpage}`)
            .then((response) => {
                setHolidays(response.data.shippingholidays || []);
                setTotalPages(response.data.pagination.total_pages || 1);
            })
            .catch((error) => console.error('Error fetching products:', error));
    };

    const handleEdit = (holiday) => {
        setFormData({ date: holiday.date, id: holiday.id });
        setShowEditModal(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };
    const handlePageChange = (e, page) => {
        setCurrentPage(page);
    };

    const handleSubmitAdd = (e) => {
        e.preventDefault();
        axios
            .post("https://api.vivekflowers.com/api/shippingholidays", formData)
            .then((response) => {
                alert(response.data.message || "Shipping Holiday added successfully.");
                setShowAddModal(false);
                fecthHolidays(currentPage);
            })
            .catch((error) => {
                console.error("Error adding Shipping Holiday:", error);
                alert("Failed to add Shipping Holiday. Please try again.");
            });
    };

    const handleSubmitEdit = (e) => {
        e.preventDefault();
        const { id, ...updatedHoliday } = formData;
        axios
            .put(
                `https://api.vivekflowers.com/api/shippingholidays/${id}`,
                updatedHoliday
            )
            .then((response) => {
                alert(response.data.message || "Shipping Holiday updated successfully.");
                setShowEditModal(false);
                fecthHolidays(currentPage);
            })
            .catch((error) => {
                console.error("Error updating Shipping Holidays:", error);
                alert("Failed to update Shipping Holidays. Please try again.");
            });
    };



    useEffect(() => {
        fecthHolidays(currentPage);
    }, [currentPage, rowsperpage]);


    return (
        <div className="container-scroller">

            <Header />

            <div className="container-fluid page-body-wrapper">

                <Sidebar />

                <div className="main-panel">

                    <div className="content-wrapper">

                        <div className="row">

                            <div className="col-lg-12 grid-margin stretch-card">

                                <div className="card">

                                    <div className="card-body">

                                        <div className="d-flex justify-content-between align-items-center">

                                            <h4 className="card-title">Festivals</h4>

                                            {/* <input type="text" className="form-control w-25 responsive-search-bar" placeholder="Search products..." value={search} onChange={(e) => setSearch(e.target.value)} style={{ maxWidth: "300px", height: '40px' }} /> */}

                                            <Button variant="primary" onClick={() => setShowAddModal(true)} className="ml-3">
                                                Add Shipping Holidays
                                            </Button>


                                        </div>

                                        <div className="table-responsive mt-4">

                                            <table className="table">

                                                <thead>

                                                    <tr>

                                                        <th>ID</th>
                                                        <th>Shipping Holidays</th>
                                                        <th>Action</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {holidays.map((holiday, index) => (
                                                        <tr key={index}>
                                                            <td>{holiday.id}</td>
                                                            <td>{holiday.date}</td>
                                                            <td>
                                                                <label className="badge badge-danger pointer" style={{ marginRight: "10px" }}>

                                                                    <i className="mdi mdi-delete" style={{ fontSize: "18px" }} onClick={() => handleRemove(holiday.id, holiday.remove)}></i>

                                                                </label>

                                                                <label className="badge badge-secondary pointer">

                                                                    <i a className="mdi mdi-storefront-edit" onClick={() => handleEdit(holiday)} style={{ fontSize: "18px" }}></i>

                                                                </label>

                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                        </div>
                                        {/* Pagination and Rows per Page */}
                                        <div className="d-flex flex-wrap justify-content-between align-items-center p-2">

                                            <FormControl variant="outlined" size="small" style={{ marginRight: '10px', marginBottom: '10px', minWidth: '80px' }} >
                                                <Select labelId="rows-per-page-label" value={rowsperpage} onChange={(e) => { setRowsPerPage(e.target.value); setCurrentPage(1); }}>
                                                    <MenuItem value={5}>5</MenuItem>
                                                    <MenuItem value={10}>10</MenuItem>
                                                    <MenuItem value={15}>15</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} variant="outlined" color="primary" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>

            {/* Modal for Adding Holiday */}
            <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Shipping Holiday</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitAdd}>
                        <Form.Group controlId="formHolidayDate">
                            <Form.Label>Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="date"
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <div className="d-flex justify-content-end mt-2">
                            <Button type="submit" variant="primary">
                                Add Holiday
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal for Editing Holiday */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Shipping Holiday</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitEdit}>
                        <Form.Group controlId="formHolidayDate">
                            <Form.Label>Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="date"
                                value={formData.date}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <div className="d-flex justify-content-end mt-3">
                            <Button type="submit" variant="primary">
                                Update Holiday
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>


    );
};

export default ShippingHolidays;
