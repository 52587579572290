import React, { useState, useEffect } from "react";
import Header from '../includes/navBar';
import Sidebar from '../includes/sideBar';
import Footer from '../includes/footer';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from './loader';

function formatNumber(number, length) {
    return String(number).padStart(length, '0');
}

const OrderDetails = () => {

    const user = sessionStorage.getItem('VVKADuser') || 0;
    const navigate = useNavigate();
    useEffect(() => {
        if (!user || user <= 0) {
            navigate('/');
        }
    }, [user]);

    const location = useLocation();
    const [order, setOrder] = useState(location.state?.order || {});
    const [unavailableDeliveryDates, setUnavailableDeliveryDates] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tempDate, setTempDate] = useState(null);
    const [deliveryDate, setDeliveryDate] = useState(order.deliveryDate || null);
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
    const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
    const [refundAmount, setRefundAmount] = useState(parseFloat(location?.state?.order?.grandTotal) || 0);
    const [refundError, setRefundError] = useState(false);
    const [refundSuccess, setRefundSuccess] = useState(false);
    const [deliveryComments, setDeliveryComments] = useState(order.deliveryComments || "");
    const [tempStatus, setTempStatus] = useState(order.status || "");
  


    const [loader, setLoader] = useState(false);

    //console.log("delivery commands",deliveryComments)
    //console.log("orders",order)

    const getShippingHolidays = async () => {
        try {
            const response = await axios.get(`https://api.vivekflowers.com/api/shippingholidays`);
            setUnavailableDeliveryDates(response.data);
        } catch (error) {
            console.error("Error fetching shipping holidays:", error);
        }
    };
    
    useEffect(() => {
        getShippingHolidays();
    }, []);

    const isUnavailableDeliveryDate = (date) => {
        const formattedDate = date.toISOString().split("T")[0];
        return unavailableDeliveryDates.includes(formattedDate);
    };

    const updateOrderStatus = (orderId, status) => {
        setLoader(true);
        axios
            .put(`https://api.vivekflowers.com/api/order/${orderId}`, { status })
            .then(() => {
                setOrder((prevOrder) => ({
                    ...prevOrder,
                    status,
                }));
                setLoader(false);
                alert('Order status updated successfully!');
            })
            .catch((error) => {
                console.error("Error updating status:", error);
                setLoader(false);
                alert('Error updating order status. Please try again.');
            });
            
    };

    const handleStatusChange = (event) => {
        const newStatus = event.target.value;
        setTempStatus(newStatus);
        setIsStatusModalOpen(true);
    };

    const handleConfirmStatusChange = () => {
        updateOrderStatus(order.orderId, tempStatus);
        setIsStatusModalOpen(false);
    };

    const handleCancelStatusChange = () => {
        setIsStatusModalOpen(false);
    };

    const handleDeliveryDateChange = (date) => {
        setTempDate(date);
        setIsModalOpen(true);
    };

    const handleConfirmDateChange = () => {
        setLoader(true);
        const formattedDate = tempDate.toISOString().split("T")[0];
        axios
            .put(`https://api.vivekflowers.com/api/order/${order.orderId}/deliveryDate`, { deliveryDate: formattedDate })
            .then(() => {
                setOrder((prevOrder) => ({
                    ...prevOrder,
                    deliveryDate: formattedDate,
                }));
                setDeliveryDate(formattedDate);
                setLoader(false);
                alert('Delivery date updated successfully!');
            })
            .catch((error) => {
                console.error("Error updating delivery date:", error);
                setLoader(false);
                alert('Error updating delivery date. Please try again.');
            });
        setIsModalOpen(false);
    };

    const handleCancelDateChange = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = async () => {
        setLoader(true);
        if (!order.orderId || !deliveryComments) {
          console.log("Please provide both Order ID and Delivery Comments.");
          return;
        }
        try {
          const response = await axios.put(
            `https://api.vivekflowers.com/api/order-delivery/${order.orderId}`,
            { deliveryComments }
          );
          setLoader(false);
          alert(response.data.message);
        } catch (error) {
          setLoader(false);
          alert(error.response?.data?.error || "An error occurred.");
        }
      };  

      const startRefund = async () => {
        if(refundAmount <= parseFloat(location?.state?.order?.grandTotal)){
            setRefundError(false);
            setLoader(true);
            const response = await axios.post(`https://api.vivekflowers.com/api/refund/${order.orderId}`, {amount: parseFloat(refundAmount)});
            console.log(response);
            if(response.status === 200){
                setRefundSuccess(`Refund of $${response.data.refundValue} initiated with ID ${response.data.refundId}`);
                setLoader(false);
            }else{
                setRefundError(response.data.error);
                setLoader(false);
            }
        }else{
            setRefundError(`Please Enter Amount less than or equal to ${location?.state?.order?.grandTotal}.`)
        }
      };
    return (

        <>
        {loader && <Loader />}
            <div className="container-scroller">
                <Header />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-lg-12 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Order Details</h4>
                                            <div className="row">
                                                {/* Order Details */}
                                                <div className="col-lg-4">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="card-title">Order Details</h5>
                                                            <p><strong>Order ID : </strong> {formatNumber(order.invNo, 7)}</p>
                                                            {/*<p><strong>Order Number :</strong> {order.orderId}</p>*/}
                                                            <p><strong>Order Type : </strong> {order.orderType}</p>
                                                            <p><strong>Customer Name : </strong> {order.fName} {order.lName}</p>
                                                            <p><strong>Email : </strong> {order.email}</p>
                                                            <p><strong>Phone : </strong> {order.phone}</p>
                                                            <p><strong>Event : </strong> {order.event}</p>
                                                            <p><strong>Event Date : </strong> {order.eventDate}</p>
                                                            <p><strong>{order.orderType === 'pickup' ? 'Pickup' : 'Delivery'} Date : </strong> {deliveryDate}</p>
                                                            <p><strong>Cart Total : </strong> ${Number(order.total).toFixed(2)}</p>
                                                            <p><strong>Shipping : </strong> ${Number(order.shipping).toFixed(2)} {order.shippingMode.length > 0 && ( `( ${order.shippingMode} )` ) }</p>
                                                            <p><strong>Grand Total : </strong> ${Number(order.grandTotal).toFixed(2)}</p>
                                                            <p><strong>Payment Ref Id : </strong> {order.payPalId}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Address Details */}
                                                <div className="col-lg-4">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="card-title">{order.orderType === 'pickup' ? 'Pickup ' : 'Delivery '}Details</h5>
                                                            <p><strong>{order.orderType === 'pickup' ? 'Pickup Store' : 'Address '}</strong></p>
                                                            {order.orderType === 'pickup' ? 
                                                                <p>{order.address1}</p>
                                                            : 
                                                                <p>{order.address1}, {order.address2}, {order.city}, {order.state}, {order.country} - {order.zip}</p>
                                                            }
                                                            <p><strong>Customer Delivery Comments</strong></p>
                                                            <p>{order.comments}</p>
                                                            <p><strong>Delivery Comments</strong></p>
                                                            <textarea id="delivery-comments" value={deliveryComments} onChange={(e) => setDeliveryComments(e.target.value)} style={{ width: "100%", border: "1px solid #CED4DA", borderRadius: "10px", padding: "10px", height: "170px", overflowY: "auto", resize: "none", }} placeholder="Enter delivery comments..." />
                                                            <button onClick={handleSubmit} style={{ marginTop: "10px", padding: "10px 15px", color: "white", border: "none", borderRadius: "5px", cursor: "pointer", fontSize: "16px", float: 'right', backgroundColor: "#2681b6" }}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Update Order Details */}
                                                <div className="col-lg-4">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="card-title">Update Order Details</h5>
                                                            <p><strong>Delivery Date</strong></p>
                                                            <DatePicker
                                                                selected={tempDate || new Date(deliveryDate)}
                                                                onChange={handleDeliveryDateChange}
                                                                filterDate={(date) => !isUnavailableDeliveryDate(date)}
                                                                dateFormat="yyyy-MM-dd"
                                                                placeholderText="Select a delivery date"
                                                                className="form-control mb-4"
                                                                minDate={new Date(order.eventDate || new Date())}
                                                            />

                                                            <p><strong>Status</strong></p>
                                                            <select className="form-select mb-4" value={order.status || ""} onChange={handleStatusChange}>
                                                                <option value="Order Placed">Order Placed</option>
                                                                <option value="Delayed">Delayed</option>
                                                                <option value="Shipped">Shipped</option>
                                                                <option value="Delivered">Delivered</option>
                                                                <option value="Cancelled">Cancelled</option>
                                                            </select>

                                                            <p><strong>Refund</strong></p>
                                                            {order.refundId ?
                                                                <p>
                                                                    Refund ID : {order.refundId} <br />
                                                                    Refund Value : ${parseFloat(order.refundValue).toFixed(2)} <br />
                                                                    Refund Initiated : {order.refundDate} <br />
                                                                </p>
                                                            :
                                                            refundSuccess ?
                                                                <p>Refund Initiated.</p>
                                                             : order.refundUrl && order.refundUrl.length > 0 ?  <button class="btn btn-warning" onClick={()=> setIsRefundModalOpen(true)}>Initiate Refund</button>
                                                                : <button class="btn btn-secondary" >Initiate Refund</button>
                                                            }
                                                            </div>
                                                    </div>
                                                </div>

                                            </div>

                                            {/* Order Items Table */}
                                            <hr />
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Product ID</th>
                                                            <th>Product</th>
                                                            <th>Price</th>
                                                            <th>Quantity</th>
                                                            <th>Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {order.order_details?.map((item) => (
                                                            <tr key={item.details_id}>
                                                                <td>{item.product_id}</td>
                                                                <td className="py-1">
                                                                    <img
                                                                        src={`https://api.vivekflowers.com/${item.product_image}`}
                                                                        alt={item.product_name}
                                                                        style={{ marginRight: "10px" }}
                                                                    />
                                                                    {item.product_name}
                                                                </td>
                                                                <td>${Number(item.product_price).toFixed(2)}</td>
                                                                <td>{item.quantity}</td>
                                                                <td>${(item.product_price * item.quantity).toFixed(2)}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
                {isModalOpen && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <h3 style={{ fontSize: '14px' }}>Are you sure you want to change the delivery date?</h3>
                            <div className="modal-actions">
                                <button onClick={handleCancelDateChange} className="cancel-btn">
                                    Cancel
                                </button>
                                <button onClick={handleConfirmDateChange} className="confirm-btn">
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Status Change */}
                {isStatusModalOpen && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <h3 style={{fontSize:'14px'}}>Are you sure you want to change the status to "{tempStatus}"?</h3>
                            <div className="modal-actions">
                                <button onClick={handleCancelStatusChange} className="cancel-btn">
                                    Cancel
                                </button>
                                <button onClick={handleConfirmStatusChange} className="confirm-btn">
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {isRefundModalOpen && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <h3 style={{fontSize:'14px'}}>Please confirm Refund Process.</h3>

                            <div class="row mt-2">
                                <div class="col-6 text-end">
                                    Order Id <br />
                                    Order Value <br />
                                    Shipping <br />
                                    Grand Total
                                </div>
                                <div class="col-6 text-start">
                                    {formatNumber(order.invNo, 7)} <br />
                                    ${Number(order.total).toFixed(2)} <br />
                                    ${Number(order.shipping).toFixed(2)} <br />
                                    ${Number(order.grandTotal).toFixed(2)}
                                </div>
                            </div>
                            
                            <div className="modal-actions mt-4">
                                <label class="mb-2">Enter Refund Amount</label>
                                <input type="number" min="0" class="form-control" value={refundAmount} onChange={(e)=> setRefundAmount(e.target.value)} />
                                {refundError && <p class="text-danger mb-2 mt-2">{refundError}</p>}
                                {refundSuccess && <p class="text-success mb-2 mt-2">{refundSuccess}</p>}
                                <button onClick={()=> setIsRefundModalOpen(false)} className="cancel-btn">
                                    Cancel
                                </button>
                                <button onClick={startRefund} className="confirm-btn">
                                    Refund
                                </button>
                                
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* Modal Styles */}
            <style jsx>{`
                .modal-overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    font-size:14px;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.5);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 9000;
                }
                .modal-content {
                    background-color: #fff;
                    padding: 50px;
                    border-radius: 8px;
                    width: 600px;
                    text-align: center;
                    margin: 5px;
                }
                .modal-actions button {
                    padding: 10px 20px;
                    margin: 10px;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: background-color 0.3s;
                }
                .cancel-btn {
                    background-color: #f44336;
                    color: #fff;
                }
                .confirm-btn {
                    background-color: #4caf50;
                    color: #fff;
                }
                .cancel-btn:hover {
                    background-color: #e53935;
                }
                .confirm-btn:hover {
                    background-color: #388e3c;
                }
            `}</style>
        </>
    );
};

export default OrderDetails;
